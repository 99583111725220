import React, { useState, useEffect } from 'react';
import MyNavBar from '../MyNavBar';
import CountEmail from './EmailModules/CountEmail';

export default function EmailPage(){

	const [emailType, setEmailType] = useState("Count Email"); // set default email type here

	const emailOptionWidgetMap = {
		'Count Email': <CountEmail />,
	}

	/**
	 * Helper to render the email component based on dropdown
	 * @returns Component
	 */
	const renderEmailComponent = () => {
		let comp = emailOptionWidgetMap[emailType];
		console.log(comp);
		return comp
	}

	return (
		<>
			<MyNavBar />
			<div className='p-4'>

				{/* Disclaimer on page */}
				<div className='w-full flex justify-center'>
					<div className='border-yellow-500 border-2 shadow-sm bg-yellow-200 text-yellow-600 rounded-lg p-4 max-w-4xl'>
						This new email tab is in beta, currently it is just for the customer
						count email, eventually all email editors will move to this tab
						and a new data tab will be created to handle editing, deleting,
						and uploading data associated with the emails as well.
					</div>
				</div>

				<div className='mt-4 w-full flex justify-center'>
					<form>
						<label>
							Select Email Type
						</label>
						<select 
							className='border border-gray-300 shadow-sm rounded-lg ml-2  px-2 py-1'
							value={emailType}
							>
							{
								Object.keys(emailOptionWidgetMap).map(type => {
									return (
										<option value={type}>{type}</option>
									)
								})
							}
						</select>
					</form>
				</div>

				<div className='mt-4'>
					{renderEmailComponent()}
				</div>

			</div>
		</>
	)
}