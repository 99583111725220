/**
	___       __    __                    ____                  __ 
   /   | ____/ /___/ /_______  __________/  _/___  ____  __  __/ /_
  / /| |/ __  / __  / ___/ _ \/ ___/ ___// // __ \/ __ \/ / / / __/
 / ___ / /_/ / /_/ / /  /  __(__  |__  )/ // / / / /_/ / /_/ / /_  
/_/  |_\__,_/\__,_/_/   \___/____/____/___/_/ /_/ .___/\__,_/\__/  
											   /_/                 
 * 
 * Ken LIpke
 * April 2021
 * 
 * Component to standardize address input
 */

import React, { useState } from 'react';
// import { Row, Col, Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { defaultAddresses } from "../locationData.js";

export default function AddressInput(props){

	const [inputString, setInputString] = useState("");

	const handleInputChange = (e) => {
		setInputString(e.target.value);
	};

	const useDefault = () => {
		console.log(props.loc);
		console.log(Object.keys(defaultAddresses));

		if (Object.keys(defaultAddresses).includes(String(props.loc))){
			props.setTo(defaultAddresses[props.loc])
		}
	}

	const useInternal = () => {
		props.setTo([
			"ken@hellometer.io",
			"alex@hellometer.io",
			"alberto@hellometer.io",
			"alexandrea@hellometer.io",
			"javi@hellometer.io",
			"giglesia@avsservices.es"
		]);
	}

	const addAddress = () => {
		if (inputString != "") {
			let n = [...props.to];
			n.push(inputString);
			props.setTo(n);

			setInputString("");
		}
	};

	const makePills = () => {
		// for each email address, make a pill
		return props.to.map((s) => {
			return (
				<div key={`addresssInput-${s}`} className="pill pill-query">
					<div className="pill-content">
						<p className="padding-right">{s}</p>
						<button
							className="pill-close"
							onClick={() => removePill(s)}
							tabIndex="0">
							X
						</button>
					</div>
				</div>
			);
		});
	};

	const makeClearButton = () => {
		if (props.to.length > 0){
			return (
				<button
					variant='outline-danger'
					className='btn border-2 p-1 border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-lg'
					onClick={() => props.setTo([])}>
						Clear All
				</button>
			)
		}
	}

	const removePill = (s) => {
		let n = [...props.to];
		n = n.filter((x) => x != s);
		props.setTo(n);
	};

	return (
		<div className='my-2'>
			<div>
				{makePills()}
				{makeClearButton()}
			</div>
			<div className='mt-3'>
				<div className='my-2'>
					<form
						onSubmit={(e) => {
							console.log("Submitting");
							e.preventDefault();
							addAddress();
						}}>
						<input text="text" placeholder="enter email address" 
							type='email'
							value={inputString}
							onChange={handleInputChange}
							className='border-2 px-2 py-1 rounded-l-lg border-gray-400'/>
						<button
							type='submit'
							className='btn py-1 px-2 bg-blue-500 border-2 border-blue-500 rounded-r-lg text-white'>
							Add
						</button>
						
					</form>
				</div>
				<div>
					<button
						onClick={useDefault}
						className='btn mx-2 border-2 px-2 py-1 rounded-lg border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'>
						Use Default
					</button>
					<button
						onClick={useInternal}
						className='btn mx-2 border-2 px-2 py-1 rounded-lg border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white'>
						Use Internal
					</button>
				</div>
			</div>
		</div>
	)

	// return (
	// 	<>
	// 		<Row className="mt-5">
	// 			{makePills()}
	// 			{makeClearButton()}
	// 		</Row>
	// 		<Row className='mt-3'>
	// 			<Col>
	// 				<form
	// 					onSubmit={(e) => {
	// 						e.preventDefault();
	// 						addAddress();
	// 					}}>
	// 					<FormGroup>
	// 						<InputGroup style={{'maxWidth':'500px'}}>
	// 							<Form.Control
	// 								placeholder="enter email address"
	// 								type='email'
	// 								value={inputString}
	// 								onChange={handleInputChange} />
	// 							<InputGroup.Append>
	// 								<Button type='submit'>Add</Button>	
	// 							</InputGroup.Append>
	// 						</InputGroup>
	// 					</FormGroup>
	// 				</form>
	// 			</Col>
	// 		</Row>
	// 		<Row>
	// 			<Col>
	// 				<Button variant="outline-primary" onClick={useDefault}>
	// 					Use Default
	// 				</Button>
	// 				&nbsp;
	// 				<Button variant="outline-secondary" onClick={useInternal}>
	// 					Use Internal
	// 				</Button>
	// 			</Col>
	// 		</Row>
	// 	</>
	// )
}