import React, { useState } from "react";
import { useDataContext } from "../DataContext";

export default function DeviceRow({ data, refreshData }) {

	const [rebooting, setRebooting] = useState(false);
	const [pinging, setPinging] = useState(false);

	const {routes} = useDataContext();

	const innerReboot = (id) => {
		// Verify we want to reboot
		let r = window.confirm("Are you sure you want to reboot?");
		if (!r){
			return;
		}

		// Make the call
		setRebooting(true);
		let url  = routes['reboot'];
		// fetch('http://localhost:5001/reboot_camera/reboot_by_id/'+id)
		fetch(url + id)
			.then(res => {
				if (res.ok){
					console.log("Worked");
				}
				else{
					throw new Error("Failed");
				}
			})
			.catch(() => {
				window.alert("Reboot failed");
			})
		refreshData((() => setRebooting(false)));
	}

	const innerPing = (id) => {
		// Make the call
		setPinging(true);
		let url  = routes['ping'];
		// fetch('http://localhost:5001/reboot_camera/ping_ssh/'+id)
		fetch(url + id)
			// .then(res => {
			// 	console.log(res);
			// 	if (res.ok){
			// 		console.log("Worked");
			// 		window.alert("Connected!");
			// 	}
			// 	else{
			// 		throw new Error("Failed");
			// 	}
			// })
			.then(res => res.text())
			.then(text => {
				window.alert(text == "up" ? "Connected!" : "Unable to connect");
			})
			.catch(() => {
				window.alert("Ran into an issue, probaby down");
			})
			.finally(() => {
				setPinging(false);
			})
	}

	const renderDT = (dt) => {
		return dt ? <span>&#128663;</span> : <span>&#128694;</span>
	}


	return (
		<tr key={`dev-${data.id}`} className='even:bg-gray-100'>
			<td className='border border-gray-200'>{data.id}</td>
			<td className='border border-gray-200'>{renderDT(data.is_drive_through)}</td>
			<td className='border border-gray-200'>{new Date(data.last_reboot).toLocaleString()}</td>
			<td className='border border-gray-200'>
				{rebooting ? <span className='text-xs text-blue-500'>Rebooting...</span> : 
				(<button
					onClick={() => innerReboot(data.id)}
					className="btn border p-1 rounded-lg text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
					Reboot
				</button>
				)}
			</td>
			<td className='border border-gray-200 text-center'>
				{pinging ? <span className='text-xs text-blue-500'>Pinging...</span> : 
				(<button
					onClick={() => innerPing(data.id)}
					className="btn border p-1 rounded-lg text-xs border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
					Ping SSH
				</button>
				)}
			</td>
		</tr>
	);
}
