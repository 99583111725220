import React, { useState } from "react";
import DetectionRow from "./DetectionRow";
import AddRegionModal from "./AddRegionModal";

export default function DataTable({ detectionData, regionData, locationData, getData, deleteMode, toDelete, setToDelete, dtFilter }) {
	const toNotShow = ["day_part", "regions", "location"];

    const [addRegionDetectionId, setAddRegionDetectionId] = useState();

    /**
     * Function that makes API call to add region with passed data
     * to the DB
     * 
     * @param {Object} data region data, object with:
     *  detection_id
     *  first_seen_datetime_utc
     *  region
     *  time_spent 
     */
    const addRegion = (data) => {
        console.log(data);
    }

	return (detectionData === undefined || regionData === undefined) ? (
		<>Loading...</>
	) : (
        <>
            <table className="w-full max-w-7xl min-w-lg border-gray-100 border rounded-lg shadow-lg">
                <thead className="bg-gray-400 text-left">
                    <tr>
                        {[
                            "DB ID",
                            "Model ID",
                            "First Seen (local)",
                            "isDriveThru",
                            "Location ID",
                            "Time Spent",
                            "Valid Time Spent"
                        ].map((colName) => {
                            return <th className='sticky top-0 bg-gray-400' key={`col-${colName}`}>{colName}</th>;
                        })}
                        <th className='text-right pr-2 sticky top-0 bg-gray-400'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        detectionData.filter(x => {
                            if (dtFilter == 'dt'){
                                return x.is_drive_through
                            }
                            else if (dtFilter == 'lobby'){
                                return !x.is_drive_through
                            }
                            else{
                                return true
                            }
                        }).map((detDat, i) => {
                            // Get matching region data
                            let regions = regionData.filter(x => x.detection_id === detDat.id);
                            return (
                                <DetectionRow 
                                    key={detDat.id}
                                    i={i}
                                    detectionData={detDat}
                                    regionData={regions}
                                    locationData={locationData}
                                    refresh={getData}
                                    setRegionAddId={setAddRegionDetectionId}
                                    deleteMode={deleteMode}
                                    toDelete={toDelete}
                                    setToDelete={setToDelete}
                                    />
                            )
                        })
                    }
                </tbody>
            </table>
            {
                addRegionDetectionId !== undefined ? (
                    <AddRegionModal
                        setDetectionId={setAddRegionDetectionId}
                        detectionData={detectionData.filter(x => x.id === addRegionDetectionId)[0]}
                        locationData={locationData}
                        save={addRegion}
                        refresh={getData}
                        />
                ) : null
            }
        </>
	);
}
