import React, { useState, useRef }  from 'react';
import BasicModal from '../utils/BasicModal';
import { useDataContext } from '../DataContext';
import { parseIsoDatetime, UTCStringToLocalDate } from "./helpers";
import dateFormat from "dateformat";
import { getTimezoneOffset, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export default function AddRegionModal({setDetectionId, detectionData, locationData, save, refresh}){

	const { routes } = useDataContext();

	const [firstSeen, setFirstSeen] = useState();
	const [region, setRegion] = useState('');
	const [timeSpent, setTimeSpent] = useState();

	const dateTimeRef = useRef();


	const dateUpdateHelper = (ds) => {
		let d = zonedTimeToUtc(ds, locationData.time_zone);
		let newVal = d.toISOString();
		setFirstSeen(newVal);
	}

	const showVal = () => {
		console.log(dateTimeRef.current.value);
		let dateTime = dateTimeRef.current.value;
		if (dateTime == ''){return}
		let d = zonedTimeToUtc(dateTime, locationData.time_zone);
		let newVal = d.toISOString();
		console.log(newVal);
	}

	/**
	 * Helper function to prep data before sending
	 * it to the passed save function which adds it to the DB
	 */
	const innerSave = () => {
		// Gather values
		let dateTime = dateTimeRef.current.value;

		// If any values are not entered, throw an error alert
		if (dateTime == '' || region == '' || timeSpent == undefined){
			window.alert("Please make sure all the fields are filled in");
			return;
		}

		// Handle date timezone
		let d = zonedTimeToUtc(dateTime, locationData.time_zone);
		let zonedDate = d.toISOString();

		// Package
		let data = {
			first_seen_datetime_utc: zonedDate,
			region: region,
			time_spent: parseInt(timeSpent),
			detection_id: detectionData.id
		}

		// Make API Call
		let url = routes['addRegion'];
		fetch(url, {
			method: 'POST',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(data => {
			console.log(data);
			refresh();
			setDetectionId(undefined);
		})
		.catch(err => {
			window.alert("Error adding region, please double check fields and try again");
		})

	}

	return (
		<BasicModal
			closeModal={() => setDetectionId(undefined)}
			title={"Add Region"}
			accept={innerSave}
			acceptText={"Add Region"}
			>
			<div>
				<div>
					<form>
						<label>First Seen (local time)</label>
						<input 
							className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
							type="datetime-local" 
							ref={dateTimeRef}
							// value={dateFormat(UTCStringToLocalDate(firstSeen, locationData.time_zone), "isoDateTime")}
							// onChange={(e) => {dateUpdateHelper(e.target.value)}}
							/>
					</form>
				</div>
				<div>
					<form>
							<label>Region</label>
							<input 
								className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
								value={region}
								onChange={(e) => {setRegion(e.target.value)}}
								/>
					</form>
				</div>
				<div>
					<form>
							<label>Time Spent</label>
							<input 
								className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
								type="number" 
								value={timeSpent}
								onChange={(e) => {setTimeSpent(e.target.value)}}
								/>
					</form>
				</div>
			</div>
		</BasicModal>
	)
}