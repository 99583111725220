import React, { useState, useEffect } from 'react';
import { useDataContext } from '../DataContext';
import Actions from './Actions';
import ViewEditCell from './ViewEditCell';

export default function RegionRow({regionData, locationData, refresh, i}){

	const { routes } = useDataContext();

	const [editMode, setEditMode] = useState(false);
	const [newData, setNewData] = useState({});

	useEffect(() => {
		// Whenever edit mode changes, clear the newData
		setNewData({});
	}, [editMode])

	const save = () => {
		// If nothing new, leave it
		if (Object.keys(newData).length === 0){setEditMode(false); return;}

		let url = routes['updateRegion'];
		let params = Object.assign({}, newData);
		params['id'] = regionData.id;
		setEditMode(false);
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			console.log(data);
			refresh();
		})
		.catch(err => console.warn(err));
	}

	const deleteRegion = () => {
		let r = window.confirm("Are you sure you want to delete this region?");
		if (r != true){return}
		
		let url = routes['deleteRegion'];
		let params = {id: regionData.id}
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		}).then(res => res.text())
		.then(text => {
			console.log(text);
			refresh();
		})
		.catch(err => {
			console.warn(err);
			window.alert("Error deleting region");
		})
	}

	const updateField = (fieldId, newValue) => {
		console.log("Update", fieldId, "with", newValue);
		let _new = Object.assign({}, newData);
		_new[fieldId] = newValue;
		setNewData(_new);
		console.log(_new);
	}

	return(
		<tr className={`${i % 2 === 1 ? 'bg-gray-300': ''} hover:bg-blue-300`}>
			<td>
				{regionData.id}	
			</td>     
			<td>
				{/* {regionData.first_seen_datetime_utc} */}
				<ViewEditCell
					fieldId={"first_seen_datetime_utc"}
					value={regionData.first_seen_datetime_utc}
					type={'datetime'}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
					/>
			</td>
			<td>
				{/* {regionData.region} */}
				<ViewEditCell
					fieldId={"region"}
					value={regionData.region}
					type={'text'}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
					/>
			</td>
			<td>
				{/* {regionData.time_spent} */}
				<ViewEditCell
					fieldId={"time_spent"}
					value={regionData.time_spent}
					type={'int'}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
					/>
			</td>
			<td className="inline-flex float-right">
				<Actions
					editMode={editMode}
					setEditMode={setEditMode}
					deleteDetection={deleteRegion}
					expanded={null}
					setExpanded={() => {}}
					save={save}
				/>
			</td>
		</tr>
	)
}