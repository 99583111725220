import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../AuthContext';
import { useDataContext } from '../DataContext';
import CustomerLocationList from './CustomerLocationList';
import CusomterAttributes from './CustomerAttributes';
import CustomerAttributers from './CustomerAttributes';

export default function CustomerInfo({ customerData, setCreatingAccount, setSelectedCustomer, reloadCustomerData }){

	// States
	const [updatingEmail, setUpdatingEmail] = useState(false);
	const [updatingPassowrd, setUpdatingPassword] = useState(false);
	const [newPassword, setNewPassword] = useState('');

	// Refs
	const newEmailRef = useRef();

	const { resetPassword } = useAuth();
	const { routes } = useDataContext();

	const updateEmail = () => {
		// Verify we want to update
		let r = window.confirm("Are you sure you want to change the email for this account?");
		if (!r){
			return;
		}

		setUpdatingEmail(true);

		// Get the new email
		let newEmail = newEmailRef.current.value;
		let params = {
			uid: customerData.auth.uid,
			email: newEmail
		}
		let url = routes['updateUser'];
		fetch(url, {
			method: "POST",
			headers: {"Content-Type": 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			setUpdatingEmail(false);
			reloadCustomerData();
		})
		.catch(e => {
			console.warn(e);
			setUpdatingEmail(false);
		});
	}

	const generateRandomPassword = () => {
		let r = (Math.random() + 1).toString(36).substring(2);
		setNewPassword(r);
	}

	const updatePassword = () => {
		if (newPassword.length < 6){
			window.alert("Please make a password at least 6 characters long");
			return;
		}

		// Verify we want to update
		let r = window.confirm("Are you sure you want to change the password for this account?");
		if (!r){
			return;
		}

		setUpdatingPassword(true);
		let params = {
			uid: customerData.auth.uid,
			password: newPassword
		}

		let url = routes['updateUser'];
		// url = 'http://localhost:5001/customer/wtf';
		fetch(url, {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			setUpdatingPassword(false);
			window.alert("Password updated!");
			// reloadCustomerData();
		})
		.catch(e => {
			console.warn(e);
			setUpdatingPassword(false);
		});
	}

	const internalPasswordReset = () => {
		let r = window.confirm("Are you sure you want to send a password reset email to the customer?");
		if (!r){
			return;
		}
		resetPassword(customerData.auth.email);
		window.alert("Email Sent!");
	}

	const deleteAccount = () => {
		let r = window.confirm("Are you sure you want to delete, this cannot be undone?");
		if (!r){
			return;
		}
		r = window.confirm("Are you ABSOLUTELY SURE?!?!");
		if (!r){
			return;
		}

		// They are sure....
		let params = {uid: customerData.auth.uid}
		let url = routes['deleteUser'];
		console.log(params);
		fetch(url, {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => {
			window.alert("User has been deleted");
			setSelectedCustomer(undefined);
		})
	}

	/**
	 * Handle the edge case for old accounts that have no firestore info
	 * give the user the option to delete the account or
	 * initialize a firestore entry for them
	 */
	if (customerData != undefined){
		if (customerData.firestore == undefined){
			return (
				<div>
					We have no firestore data for this person.... you are best
					off deleting this account and startin over
				</div>
			)
		}
	}

	return (
		<>
		{
			customerData == undefined ? "Loading..." : (
			<div className='flex flex-col p-4'>
				<div className='flex justify-between mb-2'>
					<div className='text-2xl bold '>
						{customerData.auth.email}
					</div>
					<div>
						<button
							onClick={() => {setSelectedCustomer(undefined); setCreatingAccount(true)}}
							className='btn border-2 bold px-2 py-1 text-xl rounded-full border-green-500 text-green-500 hover:bg-green-500 hover:text-white'>
							&#43;
						</button>
					</div>

				</div>
				<div>
					<b>User Id:</b> {customerData.auth.uid}
				</div>
				<div>
					<b>Account Created:</b> {(new Date(customerData.auth.user_metadata.creation_timestamp)).toLocaleString()}
				</div>
				<div>
					<b>Last Sign-in:</b> {(new Date(customerData.auth.user_metadata.last_sign_in_timestamp)).toLocaleString()}
				</div>

				<div>
					<b>Last Token Refresh:</b> {(new Date(customerData.auth.user_metadata.last_refresh_timestamp)).toLocaleString()}
				</div>

				<div className='mt-4'>
					<div className='text-lg font-bold'>
						Update Email Address
					</div>
					<div className='flex'>
						<input
							type='email'
							placeholder='New Email Address'
							ref={newEmailRef}
							className={'border-2 rounded-lg px-2 py-1 border-gray-400'}
							/>
						<button
							onClick={updateEmail}
							disabled={updatingEmail}
							className={`btn border-2 rounded-lg px-2 py-1 ml-2 border-blue-400 text-blue-400 
								${!updatingEmail ? 'hover:bg-blue-400 hover:text-white' : 'cursor-wait'}`}>
							Update
						</button>
					</div>
				</div>

				<div className='mt-4'>
					<div className='text-lg font-bold'>
						Update Password
					</div>
					<div className='flex'>
						<input
							type='text'
							placeholder='New Password'
							onChange={(e) => setNewPassword(e.target.value)}
							value={newPassword}
							className={'border-2 rounded-lg px-2 py-1 border-gray-400'}
							/>
						<button
							onClick={generateRandomPassword}
							className={`btn border-2 px-2 ml-2 rounded-lg border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white`}>
							Random Password
						</button>
						<button
							onClick={updatePassword}
							disabled={updatingPassowrd}
							className={`btn border-2 rounded-lg px-2 py-1 ml-2 border-blue-400 text-blue-400 
								${!updatingPassowrd ? 'hover:bg-blue-400 hover:text-white' : 'cursor-wait'}`}>
							Update
						</button>
					</div>
				</div>

				<div className='mt-2'>
					<button
						onClick={internalPasswordReset}
						className='btn border-2 rounded-lg px-2 py-1 border-red-500 text-red-500 hover:bg-red-500 hover:text-white'>
						Send Password Reset Email
					</button>
				</div>

                <div className='mt-4'>
                    <CustomerAttributers
                        customerData={customerData}
                        reloadCustomerData={reloadCustomerData}
                        />
                </div>

				<div className='mt-4'>
					<CustomerLocationList 
						customerData={customerData}
						reloadCustomerData={reloadCustomerData}/>
				</div>

				<div className='mt-8'>
					<div className='text-lg border-t-2 border-gray-400'>
						Drop Account
					</div>
					<button
						onClick={deleteAccount}
						className='btn border-2 px-2 py-1 rounded-lg border-red-500 text-red-500 hover:bg-red-500 hover:text-white'>
						Delete
					</button>
				</div>
			</div>
			)
		}
		</>
	)
}