/**
	______    ___ __             ____        __  _                 
   / ____/___/ (_) /_____  _____/ __ \____  / /_(_)___  ____  _____
  / __/ / __  / / __/ __ \/ ___/ / / / __ \/ __/ / __ \/ __ \/ ___/
 / /___/ /_/ / / /_/ /_/ / /  / /_/ / /_/ / /_/ / /_/ / / / (__  ) 
/_____/\__,_/_/\__/\____/_/   \____/ .___/\__/_/\____/_/ /_/____/  
								  /_/                              
 * Ken Lipke
 * April 2021
 * 
 * Holds the options for the email editor
 */

import React, { useEffect, useState } from "react";
// import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { locationMap, storeNumbers } from "../locationData";
import { CircularProgress } from "@material-ui/core";
import app from '../firebase';

export default function EditorOptions(props) {
	// let dayParts = ["Breakfast", "Lunch", "Afternoon", "Dinner"];
	const [dayParts, setDayParts] = useState([]);
	let locations = [2, 8, 9, 10, 11, 12, 13, 3, 19];
	// const [loc, setLoc] = useState(locations[0]);

	const locationSelectChange = (e) => {
		props.setLoc(e.target.value);
	};

	// On load and location change, get day parts from firebase
	useEffect(() => {
		getDayParts(props.loc);
	}, [props.loc])

	/**
	 * Takes in a location ID, hits firebase and gets the day
	 * parts names for the given location
	 * @param {Integer} lid 
	 */
	const getDayParts = (lid) => {
		// setDayParts([]);
		// console.log(lid);
		// return;
		
		// ensure lid is a string
		if (lid == 'summary'){
			// Make the cross of day parts and locations
			// let options = [];
			// Object.values(storeNumbers).forEach(store => {
			// 	["Breakfast", "Lunch", "Afternoon", "Dinner"].forEach(dp => {
			// 		options.push(`${store}, ${dp}`)
			// 	})
			// })
			// setDayParts(options);
			// return;

			let options = [];
			fetch("https://api-iaily4lvlq-uc.a.run.app/location/getLocations", {
				method: "POST",
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ids: [2, 8, 9, 10, 11, 12, 13, 23]})
			})
			.then(r => r.json())
			.then(data => {
				let stores = data.map(x => x.display_name);
				stores.forEach(store => {
					["Breakfast", "Lunch", "Afternoon", "Dinner"].forEach(dp => {
						options.push(`${store}, ${dp}`);
					})
				});
				setDayParts(options);
			});
			return;
		}
		else if (lid == "summary_dq"){
			let options = [];
			fetch("https://api-iaily4lvlq-uc.a.run.app/location/get_all/5")
				.then(r => r.json())
				.then(data => {
					let stores = data.map(x => x.display_name);
					stores.forEach(store => {
						["Breakfast", "Lunch", "Afternoon", "Evening"].forEach(dp => {
							options.push(`${store}, ${dp}`);
							options.push(`${store}, ${dp}, DT`);
						})
					});
					setDayParts(options);
				});
			return;
		}
        else if (lid === 'avs'){
            let options = [];
			fetch("https://api-iaily4lvlq-uc.a.run.app/location/get_all/9")
				.then(r => r.json())
				.then(data => {
					let stores = data.map(x => x.display_name);
					stores.forEach(store => {
						["Breakfast", "Lunch", "Afternoon", "Dinner"].forEach(dp => {
							options.push(`${store}, ${dp}`);
						})
					});
					setDayParts(options);
				});
			return;
        }
		else{
			lid = lid.toString();
		}

		const db = app.firestore();
		db.collection('location')
			.doc(lid)
			.get()
			.then(r => {
				let dayPartData = r.data();
				let partNames = dayPartData['day_part_preferences'].map(x => x.name);
				setDayParts(partNames);
			})
			.catch(e => {
				console.warn(e)
				setDayParts([])
			});
	}

	const onCheck = (e, which) => {
        console.log(which);
		let n = Object.assign({}, props.toInclude);
		n[which] = !n[which];
		console.log(n);
		props.setToInclude(n);
	};

	const showSpinner = () => {
		if (!props.canBuild){
			// return <Spinner as='span' animation='border' size='sm' role='status'/>
			return <CircularProgress size='1rem' thickeness={5}/>
		}
	}

	return (
		<>
			<div>
				Select Location
				<select
					as="select"
					size="lg"
					value={props.loc}
					className='border-2 border-gray-600 p-2 rounded-lg ml-2 cursor-pointer'
					onChange={locationSelectChange}>
						<option value='summary'>SubMeter Summary</option>
						<option value='summary_dq'>DQ Summary</option>
						<option value='roundup'>SubMeter Roundup</option>
						<option value='dq_roundup'>DQ Roundup</option>
						<option value='old_summary'>Old Summary</option>
                        <option value='avs'>AVS Summary</option>
                        <option value='avs_roundup'>AVS Roundup</option>
                        <option value="snap_north_roundup">Snap North Roundup</option>
						{/* {locations.map((id) => {
							return <option value={id}>{locationMap[id]}</option>;
						})} */}
						{
							props.locationData.map(x => <option key={`editorOption-${x.id}`} value={x.id}>{x.display_name}</option>)
						}
				</select>
			</div>

			<div className='mt-4'>
				<div className='flex justify-between border-b-2 border-black'>
					<div>
						Insight
					</div>
					<div>
						<input
							type="checkbox"
							onChange={(e) => onCheck(e, "Insight")}
							checked={props.toInclude["Insight"] ? "checked" : null}
						/>
					</div>
				</div>

				{
					dayParts.map(dp => {
						return (
							<div className='flex justify-between border-b-2 border-black'
								key={`${props.location_id}--${dp}`}>
								<div>
									{dp}
								</div>
								<div>
									<input
										type="checkbox"
										onChange={(e) => onCheck(e, dp)}
										checked={props.toInclude[dp] ? "checked" : null}
									/>
								</div>
							</div>
						)
					})
				}
			</div>

			<div className='mt-4 flex justify-center'>
				<button
					onClick={props.build}
					disabled={!props.canBuild || !props.canSend}
					className='w-1/2 border-2 p-3 border-green-600 text-green-600 hover:bg-green-600 hover:text-white rounded-lg'>
					{showSpinner()} &nbsp; Build and Review
				</button>
			</div>
		</>
	);

}
