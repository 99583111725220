/**
 * Helper component used in the Add Detection modal
 * this has the fields to add region for a new detectionn
 * NOTE: this is not the componene that is used to 
 * add regions to already created DB detections
 */

import React, { useState, useRef, useEffect } from 'react';

export default function AddRegionForDetection({locationData, uid, removeRegion, updateData}){

	const [region, setRegion] = useState('');
	const [timeSpent, setTimeSpent] = useState(0);
	const [firstSeen, setFirstSeen] = useState('');
	const dateTimeRef = useRef();

	useEffect(() => {
		updateData(uid, {
			'region': region,
			'first_seen_datetime_utc': firstSeen,
			'time_spent': timeSpent
		})
	}, [region, timeSpent, firstSeen]);

	
	return (
		<div
			className='flex flex-wrap gap-4 border-b pb-2 text-sm'>
			<form>
				<label>
					First Seen
				</label>
				<input 
					className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
					type="datetime-local" 
					value={firstSeen}
					onChange={e => setFirstSeen(e.target.value)}
					// ref={dateTimeRef}
					/>
			</form>

			<form>
				<label>
					Region
				</label>
				<input 
					className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
					value={region}
					onChange={(e) => {setRegion(e.target.value)}}
					/>
			</form>

			<form>
				<label>
					Time Spent
				</label>
				<input 
					className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
					type="number" 
					value={timeSpent}
					onChange={(e) => {setTimeSpent(e.target.value)}}
					/>
			</form>

			<button 
				onClick={() => removeRegion(uid)}
				className='btn border text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-1 rounded-lg'>
				Remove Region
			</button>

		</div>
	)
}