/**
	 ______                _ ________    ___ __            
   / ____/___ ___  ____ _(_) / ____/___/ (_) /_____  _____
  / __/ / __ `__ \/ __ `/ / / __/ / __  / / __/ __ \/ ___/
 / /___/ / / / / / /_/ / / / /___/ /_/ / / /_/ /_/ / /    
/_____/_/ /_/ /_/\__,_/_/_/_____/\__,_/_/\__/\____/_/     
														  

 * Ken Lipke
 * April 2021
 * 
 * Parent page for email editor
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MyNavBar from '../MyNavBar';
import EditorOptions from './EditorOptions';
import SendOptions from './SendOptions';
import EmailPreview from './EmailPreview';
import DataViewer from './DataViewer';
import CronOptions from './CronOptions';
import DataUploader from './DataUploader';
import { defaultAddresses, defaultToInclude } from '../locationData';
import { KeyboardReturnOutlined } from '@material-ui/icons';

export default function EmailEditor(props){

	// const routePrefix = "http://localhost:5000/";
	const routePrefix = "https://emails-test-dot-hellometer.uc.r.appspot.com/";

	// Define States
	const [loc, setLoc ] = useState('summary'); // initialize to a random loc
	// const [toInclude, setToInclude] = useState({
	// 	Breakfast: true,
	// 	Lunch: true,
	// 	Afternoon: true,
	// 	Dinner: true,
	// 	Insight: true,
	// });
	const [toInclude, setToInclude] = useState(defaultToInclude);
	const [canBuild, setCanBuild] = useState(true);
	const [locationData, setLocationData] = useState();

	const [to, setTo] = useState([]);
	const [canSend, setCanSend] = useState(true);
	const [viewTrigger, setViewTrigger] = useState(0);

	/**
	 * On load, get data for all the locations (we need the timezones)
	 */
	useEffect(() => {
		let params = {
			ids: [2, 3, 8, 9, 10, 11, 12, 13, 19, 20, 21, 22, 23, 24]
		};
		fetch("https://api-iaily4lvlq-uc.a.run.app/location/getLocations", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => setLocationData(data));
	}, [])

	/**
	 * Clears the to address when location changes
	 */
	useEffect(() => {
		setTo([]);
	}, [loc])

	const useDefault = () => {
		// Get the defaults for this location
		if (Object.keys(defaultAddresses).includes(loc.toString())){
			let adds = defaultAddresses[loc];
			setTo(adds);
		}
	}


	const build = () => {
		// determine if builidng summary or daily
		if (loc == "summary" || loc == "summary_dq" || loc === 'avs'){
			buildSummary(loc);
		}
		// else if ((loc == "roundup"){
		else if (loc.includes("roundup")){
			buildSummary(loc);
		}
		else if (loc == "old_summary"){
			buildOldSummary();
		}
		else{
			buildDaily();
		}
	}

	const buildDaily = () => {
		// Prevent overbuilding
		setCanBuild(false);

		// determine end point
		let url = routePrefix + "build_daily";

		

		// Organize Params
		let comps = ['3', '19'].includes(loc) ? [3, 19] : [2, 8,  9, 10, 11, 12, 13]
		let nerf = Object.keys(toInclude).filter(x => !toInclude[x]);
		console.log(nerf);
		let params = {
			'locations':[
				{
					'location_id': loc,
					'comparisons': comps,
					'date': 'today',
					'nerf': nerf
				}
			]
		}; // no interface for dates yet TODO!

		// New Daily Email BUild Route
		if (loc == 24){
			url = routePrefix + "build_daily_new";
			params = params['locations'][0];
			console.log(params);
		}

		// make request
		fetch(url, {
			method:'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		}).then(data => {
			console.log("HERE");
			setCanBuild(true);
		}).catch(e => {
			setCanBuild(true);
			window.alert("Had an issue building, try again");
		})

	}

	const buildSummary = (type) => {
		// Prevent overbuilding
		setCanBuild(false);
		console.log("Type", type);

		// Organize Params
		let nerf = Object.keys(toInclude).filter(x => !toInclude[x]);
		let params = {
			'nerf': nerf
		}; // no interface for dates yet TODO!

		console.log(params);

		// determine end point
		let url;
		let franchise;
		let include_drivethru;
		if (type == 'summary' || type == 'summary_dq'){
			url = routePrefix + "build_summary";
			if (type == 'summary_dq'){
				franchise = 5;
				include_drivethru = true;
			}
			else{
				franchise = 1;
				include_drivethru = false;
			}
			params['franchise'] = franchise;
			params['dt'] = include_drivethru;
		}
		else if (type == 'roundup'){
			url = routePrefix + 'build_roundup';
			params['franchise'] = 1
		}
		else if (type == 'dq_roundup'){
			url = routePrefix + 'build_roundup';
			params['franchise'] = 5
		}
        else if (type == 'avs_roundup'){
			url = routePrefix + 'build_roundup';
			params['franchise'] = 9
		}
        else if (type == 'avs'){
            url = routePrefix + "build_summary";
            params['franchise'] = 9;
        }
        else if (type == 'snap_north_roundup'){
            url = routePrefix + "build_roundup";
            params['franchise'] = 10;
        }

		console.log("Params", params);

		// setCanBuild(true);
		// return;

		// make request
		fetch(url, {
			method:'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		}).then(data => {
			console.log("HERE");
			setCanBuild(true);
		}).catch(e => {
			setCanBuild(true);
			window.alert("Had an issue building, try again");
		})

	}

	const buildOldSummary = () => {
		setCanBuild(false);
		let url = "https://us-central1-hellometer.cloudfunctions.net/email_report_build_test";

		// let id = loc == "summary" ? loc : parseInt(loc);
		let params = {
			"build" : [
				{
					"location_id": 'summary',
					"comparisons": [2, 8, 9, 10, 11, 12, 13],
					"nerf": []
				}
			]
		}

		console.log(params);

		fetch(url, {
			method:'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		}).then(data => {
			console.log("HERE");
			setCanBuild(true);
		}).catch(e => {
			setCanBuild(true);
			window.alert("Had an issue building, try again");
		})
	}



	/**
	 * Gets the URL for the email in the storage bucket
	 */
	const getEmailURL = () => {

		// Before I noticed issue with month switch
		// let d = new Date();
		// let day = d.getDate() - 1;
		// let month = d.getMonth() + 1;
		// let year = d.getFullYear().toString().substring(2, 4);

		let d = new Date(); d.setDate(d.getDate()-1);
		let day = d.getDate();
		let month = d.getMonth() + 1;
		// let year = d.getFullYear().toString().substring(2, 4);
		let year = d.getFullYear().toString();

		// Process for strings
		day = day < 10 ? "0" + day : day.toString();
		month = month < 10 ? "0" + month : month.toString();
		let url;
		// TODO: Update this
		if (loc == "summary"){
			url = `https://storage.googleapis.com/hellometer_email_files/summary/${year}/${month}/${day}/subsource-summary.html`
		}
		else if (loc == "summary_dq"){
			url = `https://storage.googleapis.com/hellometer_email_files/summary/${year}/${month}/${day}/dq-summary.html`
		}
		else if (loc == "roundup"){
			url = `https://storage.googleapis.com/hellometer_email_files/roundup/${year}/${month}/${day}/roundup.html`
		}
		else if (loc == "dq_roundup"){
			url = `https://storage.googleapis.com/hellometer_email_files/roundup/${year}/${month}/${day}/dq_roundup.html`
		}
		else if (loc == "old_summary"){
			let year = d.getFullYear().toString().substring(2, 4);
			url = `https://storage.googleapis.com/hellometer_email_files/summary/${day}_${month}_${year}--subsource_summary.html`;
		}
        else if (loc == 'avs'){
            url = `https://storage.googleapis.com/hellometer_email_files/summary/${year}/${month}/${day}/avs-summary.html`
        }
        else if (loc == "avs_roundup"){
			url = `https://storage.googleapis.com/hellometer_email_files/roundup/${year}/${month}/${day}/f9_roundup.html`
		}
        else if (loc == "snap_north_roundup"){
			url = `https://storage.googleapis.com/hellometer_email_files/roundup/${year}/${month}/${day}/f10_roundup.html`
		}
		else{
			url = `https://storage.googleapis.com/hellometer_email_files/${loc}/${year}/${month}/${day}/email.html`
		}
		return url;
	}

	const send = () => {
		// Verify that there is a to
		if (to.length == 0){
			window.alert("You need to send this to someone...");
			return;
		}

		// Verify we wan tto send
		let v = window.confirm(`Are you sure you watn to send to ${to.join(', ')}`);
		if (!v){
			return;
		}

		// console.log("PREVENTING SEND FOR TESTING");
		// return;

		setCanSend(false);
		
		// let d = new Date(); d.setDate(d.getDate()-1);
		// let date = `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;
		let date = 'today'
		let sendURL = getEmailURL();
		// let id = loc == "summary" ? loc : parseInt(loc);

		let innerParams = {
			'date': date,
			'link': sendURL,
			'to': to,
		};

		let messageType;
		let id;
		let franchise = -1;
		if (isNaN(loc)){
			// Not a location id (is summary or roundup)
			id = -1
			if (['summary', 'summary_dq'].includes(loc)){
				messageType = 'summary';
				franchise = loc == 'summary' ? 1 : 5;
			}
			else if (loc.includes("roundup")){
				// roundup
				messageType = "roundup";
				// franchise = loc == 'dq_roundup' ? 5 : 1;
                if (loc == 'roundup'){
                    franchise = 1;
                }
                else if (loc == 'dq_roundup'){
                    franchise = 5;
                }
                else if (loc == 'avs_roundup'){
                    franchise = 9;
                }
                else if (loc ==  "snap_north_roundup"){
                    franchise = 10;
                }
			}
            else if (loc === 'avs'){
                messageType = 'summary';
                franchise = 9;
            }
		}
		else{
			messageType = 'daily';

			if (['21'].includes(loc)){
				messageType = 'new_daily';
			}

			id = parseInt(loc);
		}

		// let messageType = (loc == 'summary' ? 'summary' : 'daily');

		// Organize the params
		let params = [
			{
				"link": sendURL,
				"location_id": id,
				"date": date,
				"to": to,
				"message_type": messageType,
				'franchise': franchise,
			}
		];

		// Make request
		let url = routePrefix + "send_link";
		fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		}).then(data => {
			setCanSend(true);
			window.alert("Sent!");
		}).catch(e => {
			setCanSend(true);
			window.alert("Had an issue sending.... try again?");
		});

	}

	return (
		<>
			<MyNavBar />
			<div className='grid grid-cols-1 sm:grid-cols-2 gap-4 p-3 my-8'>
				<div className='text-red-500'>
					{/* This is the left hand side content and I am not sure that this
					is working as one would expect it to work and that 
					si concerning as well */}
					<EmailPreview 
						location_id={loc}
						getURL={getEmailURL}
						canBuild={canBuild} />
				</div>
				<div>
					{/* This is just a bunch of random text because I am certain
					that what I have there i snot working as expected and I
					want to make sure that we get it right */}
					<div>
						<EditorOptions 
							loc={loc}
							setLoc={setLoc}
							locationData={locationData || []}
							toInclude={toInclude}
							setToInclude={setToInclude}
							canBuild={canBuild}
							canSend={canSend}
							build={build}/>
					</div>
					<div>
						<SendOptions 
							loc={loc}
							canSend={canSend}
							canBuild={canBuild}
							send={send}
							to={to}
							setTo={setTo}
							useDefault={useDefault}/>
					</div>
					<div>
						<CronOptions 
							loc={loc}
							getURL={getEmailURL}/>
					</div>

				</div>
			</div>

			<div>
				<DataViewer
					location_id={loc}
					viewTrigger={viewTrigger}
					setViewTrigger={setViewTrigger} />
			</div>

			<div className='my-8'>
				<DataUploader
					location_id={loc}
					locationData={locationData}
					viewTrigger={viewTrigger}
					setViewTrigger={setViewTrigger} />
			</div>
		</>
	);

	return(
		<>
			<MyNavBar />
			<Container fluid>
				<Row className='mt-4'>
					<Col>
						<EmailPreview 
							location_id={loc}
							getURL={getEmailURL}
							canBuild={canBuild} />
					</Col>
					<Col>
						<Row>
							<Col>
								<EditorOptions 
									loc={loc}
									setLoc={setLoc}
									locationData={locationData || []}
									toInclude={toInclude}
									setToInclude={setToInclude}
									canBuild={canBuild}
									canSend={canSend}
									build={build}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<SendOptions 
									loc={loc}
									canSend={canSend}
									canBuild={canBuild}
									send={send}
									to={to}
									setTo={setTo}
									useDefault={useDefault}/>
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								<CronOptions 
									loc={loc}
									getURL={getEmailURL}/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='mt-5 mb-5'>
					<Col >
							<DataViewer
								location_id={loc}
								viewTrigger={viewTrigger}
								setViewTrigger={setViewTrigger} />
					</Col>
				</Row>
				<Row className='mt-5 mb-5'>
					<Col >
							<DataUploader
								location_id={loc}
								locationData={locationData}
								viewTrigger={viewTrigger}
								setViewTrigger={setViewTrigger} />
					</Col>
				</Row>
			</Container>
		</>
	)
}