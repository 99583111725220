import React from 'react';

export default function CustomerPlaceholder({setCreatingAccount}){
	return(
		<div className='flex items-center justify-center h-full w-full'>
			<div>
				<div className='text-xl text-gray-600 italic'>
					Select Customer in Table
				</div>
				<div className='text-center my-8 text-gray-600'>
					Or
				</div>
				<button
					onClick={() =>setCreatingAccount(true)}
					className='btn w-full px-3 py-2 border-2 rounded-lg border-blue-500 text-blue-500 transition duration-200 hover:bg-blue-500 hover:text-white'>
					Create New User
				</button>
			</div>
		</div>
	)
}