import React, { useState, useEffect } from 'react';
import app from '../firebase';
import AddressInput from './AddressInput';


export default function CronModule({emailId, cronId, parameters}){

	const [to, setTo] = useState([]);
	const [cronData, setCronData] = useState(); // this is the entire cron
	// data the is in firestore

	/**
	 * On load and emailId change, get the cron data
	 */
	useEffect(() => {
		getTodaysCron();
	}, [cronId])

	const updateCron = () => {
		// Copy cronData (firestore object)
		let newData = Object.assign({}, cronData);

		// If this cronId is there, update it
		let newParams = Object.assign({}, parameters);
		newParams['to'] = to
		newData[cronId] = newParams;

		// If there are no to's, delete it
		if (to.length == 0){
			delete newData[cronId];
		}

		// send to firebase
		const db = app.firestore();
		let d = getTodaysDate();
		db.collection("email_cron")
			.doc(d)
			.set(newData)
			.then(r => {
				getTodaysCron();
			})
			.catch(e => console.warn(e));
		
	}

	/**
	 * Gets whatever is in Firebase for today, saves it to cronData
	 * and updates to if there is data for this cronId
	 */
	const getTodaysCron = () => {
		// Clear anything while we wait
		setTo([]);

		// get today's date
		let d = getTodaysDate();

		// query the data
		const db = app.firestore();
		db.collection('email_cron')
			.doc(d)
			.get()
			.then(r => r.data())
			.then(d =>{
				setCronData(d);

				// If no entry for this location, do nothing yet
				if (d == undefined){return}

				// set the TO based on cron job
				if (Object.keys(d).includes(String(cronId))){
					setTo(d[cronId]['to']);
				}
			})
			.catch(e => console.warn(e));
	}

	const clearAll = () => {
		let v = window.confirm("This will clear ALL cron jobs for all locations, are you sure?");
		if (!v){
			return;
		}

		// Send to firebase
		const db = app.firestore();
		let d = getTodaysDate();
		db.collection('email_cron')
			.doc(d)
			.delete()
			.then(r => getTodaysCron())
	}

	const getTodaysDate = () => {
		let d = new Date();
		let year = d.getFullYear();
		let month = d.getMonth() + 1;
		month = month < 10 ? '0' + month : month;
		let day = d.getDate();
		day = day < 10 ? '0' + day : day;
		return `${month}-${day}-${year}`
	}

	return (
		<div className='text-left p-4 border border-gray-200 rounded shadow-lg max-w-xl'>
			<h2 className='text-bold text-xl'>
				Cron
			</h2>

			<AddressInput 
				loc={emailId}
				to={to}
				setTo={setTo}
				/>

			<div className='w-full flex justify-center gap-2'>
				<button className='btn bg-green-500 text-white hover:bg-green-700 rounded-lg p-2'
					onClick={updateCron}>
					Update Cron
				</button>
				<button className='btn bg-yellow-300 hover:bg-yellow-500 rounded-lg p-2'
					onClick={getTodaysCron}>
					Refresh Cron
				</button>
				<button className='btn bg-red-500 text-white hover:bg-red-700 rounded-lg p-2'
					onClick={clearAll}>
					Clear all cron
				</button>
			</div>
		</div>
	)
}