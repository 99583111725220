import React, { useState, useEffect, useRef } from 'react';
import app from '../firebase';


export default function CustomerAttributers({customerData, reloadCustomerData}){

    const [videoAccess, setVideoAccess] = useState(true);
    const videoToggleRef = useRef();

    useEffect(() => {
        if (Object.keys(customerData.firestore).includes('has_video_access')){
            setVideoAccess(customerData.firestore['has_video_access']);
        }
        else{
            setVideoAccess(true);
        }
    }, [customerData])

    const updateFirestoreParam = (data) => {
        const db = app.firestore();
		db.collection("customer")
			.doc(customerData.auth.uid)
			.update(data)
			.catch(e => console.warn(e));
    }

    const toggleVideoAccess = () => {
        let currentVal = videoToggleRef.current.checked;
        
        // Update firestore
        updateFirestoreParam({'has_video_access':currentVal});
     
        // Update FE in interim
        setVideoAccess(currentVal);
    }

    return (
        <div>
            <h2 className='text-lg font-bold'>Firestore Preferences</h2>
                <label>
                    Has Video Access
                    <input 
                        className='ml-2'
                        type='checkbox'
                        checked={videoAccess} 
                        ref={videoToggleRef} 
                        onChange={toggleVideoAccess}/>
                </label>
        </div>
    )
}