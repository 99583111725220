import React, { useEffect, useState } from 'react';
import { useDataContext } from '../DataContext';
import { useAuth } from "../AuthContext";

export default function AddLocationModal({closeModal, currentLocations, addLocations}){

	const [allLocations, setAllLocations] = useState();
	const [displayLocations, setDisplayLocations] = useState();
	const [addingLocation, setAddingLocation] = useState(false);
	const [toAdd, setToAdd] = useState([]);

	const { routes } = useDataContext();
	const { currentUser } = useAuth();

	// Get all the locations to show on load
	useEffect(() => {
		setAllLocations(undefined);
		let url = routes['getAllLocations'];
		fetch(url)
			.then(res => res.json())
			.then(data => {
				if (currentUser.email === 'giglesia@avsservices.es' || currentUser.email === 'systemsupport@avsinvestigation.es') {
					const filteredLocations = data.filter(location => [9, 28, 30, 38].some(val => location.franchise_id === val))
					setAllLocations(filteredLocations)
					setDisplayLocations(filteredLocations);
				}
				else {
					setAllLocations(data);
					setDisplayLocations(data);
				}
			})
			.catch(err => {
				console.warn(err);
				window.alert("Couldn't load locations, close modal and try again");
			})
	}, []);

	const innerAddLocation = (id) => {
		console.log("Trhing to check or uncheck", id);
		if (toAdd.includes(id)){
			setToAdd(toAdd.filter(x => x != id));
		}
		else{
			let addCopy = [...toAdd]
			addCopy.push(id);
			setToAdd(addCopy);
		}
	}

    const filterDisplay = (event) => {
        let val = event.target.value.toLowerCase();
        if (val == ''){
            setDisplayLocations(allLocations);
            return;
        }
        let matches = allLocations.filter(l => {
            if ( (l.address !== undefined & l.address !== null)){
                if (l.address.toLowerCase().includes(val) ){
                    return true;
                }
            }
            if ( (l.display_name !== undefined & l.display_name !== null)){
                if (l.display_name.toLowerCase().includes(val)){
                    return true;
                }
            }
            if ( (l.franchise.name !== undefined & l.franchise.name !== null)){
                if (l.franchise.name.toLowerCase().includes(val)){
                    return true;
                }
            }
        });
        setDisplayLocations(matches);
    }


	return (
		<>
		<div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
			<div className='relative w-full my-6 mx-auto max-w-3xl'>
				
				{/* Modal Content */}
				<div className='border-0 rounded-lg shadow-lg relative w-full bg-white p-4'>
					{/* Modal Header */}
					<div className='text-2xl p-2 w-full border-b-2 border-gray-300'>
						Add Location
					</div>

					{/* Modal Body */}
					<div className='p-2 max-h-60 overflow-y-auto'>
                        <input 
                            type='text' 
                            placeholder='Filter Locations by Address, display name, or franchise'
                            className='w-full p-2 m-y-1 rounded-sm border border-gray-400'
                            onChange={filterDisplay}/>
						{displayLocations != undefined ? (
							<>
							<table className='table table-auto w-full'>
								<thead className='bg-gray-200 text-gray-600 font-bold text-left'>
									<tr>
										<th>id</th>
										<th>Address</th>
										<th>Display Name</th>
										<th>Franchise</th>
										<th>Add</th>
									</tr>
								</thead>
								<tbody>
									{
										displayLocations.filter(x => !currentLocations.includes(x.id))
											.sort((a,b) => a.id - b.id)
											.map(loc => {
												return(
													<tr
														className='even:bg-gray-100 hover:bg-green-100'
														key={`addLocRow-${loc.address}`}>
														<td>{loc.id}</td>
														<td>{loc.address}</td>
														<td>{loc.display_name}</td>
														<td>{loc.franchise.name}</td>
														{/* <td
															onClick={() => innerAddLocation(loc.id)}
															className='hover:text-green-600 text-center cursor-pointer'>
															<AddCircleOutlineIcon />
														</td> */}
														<td>
															<input
																checked={toAdd.includes(loc.id)}
																onChange={() => innerAddLocation(loc.id)}
																type='checkbox'
															/>
														</td>
													</tr>
												)
											})
									}
								</tbody>
							</table>
							</>
						) : <>Loading...</>}
					</div>

					{/* Footer */}
					<div className='flex justify-end border-t-2 border-gray-300 p-4 gap-2'>
						<button
							onClick={closeModal}
							className='btn p-2 px-8 border-2 rounded-lg border-red-500 text-red-500 hover:bg-red-500 hover:text-white'>
							Close
						</button>
						<button
							onClick={() => {addLocations(toAdd); closeModal();}}
							className='btn p-2 px-8 border-2 rounded-lg border-green-500 text-green-500 hover:bg-green-500 hover:text-white'>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>

		{/* Backdrop */}
		<div className='opacity-25	 fixed inset-0 z-40 bg-black'></div>
		</>
	)
}