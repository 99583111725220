import React from 'react';
import dateFormat from 'dateformat';


export default function DateSelector({date, setDate}){

    const updateDate = (dateStr) => {
        let date_parts = dateStr.split('-')
		let newDate = new Date(date_parts[0], date_parts[1]-1, date_parts[2]);
        setDate(newDate);
    }

    return(
        <form>
            <label>Select Date</label>
            <input 
                className='border border-gray-300 shoadow-sm rounded-lg ml-2 px-2 py-1'
                type='date'
                value={dateFormat(date, 'isoDate')}
                onChange={(e) => {updateDate(e.target.value)}}
                />
        </form>
    )
}