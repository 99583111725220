import React, { useState, useEffect } from 'react';
import DeviceRow from './DeviceRow';
import LinkSection from './LinkSection';
import app from '../firebase';

export default function LocationItem({ data, reboot, refreshData }){

    const [firestoreData, setFirestoreData] = useState();
    const [urlData, setUrlData] = useState();

    // ON load, get the firestore data for this location
    useEffect(() => {
        const db = app.firestore();
        db.collection('location')
            .doc(String(data.id))
            .get()
            .then(r => {
                let dat = r.data();
                setFirestoreData(dat);
                if (Object.keys(dat).includes('urls')){
                    console.log(dat.urls['recovery_server']);
                    setUrlData(dat.urls);
                }
                // console.log(dat);
            })
            .catch(e => {
                console.warn(e);
            });
    }, [])

    // console.log(data);
    return (
        <div
            className='border border-gray-200 rounded-lg p-4 my-2 shadow-lg hover:shadow-xl hover:border-blue-500'>
            <div className='flex justify-between'>
                <div>
                    <b>Location {data.id}</b> <br />
                    {data.address}<br/>
                    {
                        data.devices.length > 0 ? <>ssh -p {data.devices[0].port} hellometer@{data.devices[0].host}</> : null
                    }
                    <br></br>
                    {
                        <>OpenVPN: ssh -p 22 hellometer@pc.{data.id}.hellometer.openvpn.com</>
                    }
                </div>
                <div className='italic'>
                    {data.display_name}
                </div>
            </div>
            <div className='border-t border-gray-100'>
                <table className='border-collapse border border-gray-100'>
                    <thead className='italic'>
                        <tr>
                            <td className='border border-gray-200'>Device Id</td>
                            <td className='border border-gray-200'>Type</td>
                            <td className='border border-gray-200'>Last Reboot</td>
                            <td className='border border-gray-200'>Reboot</td>
                            <td className='border border-gray-200'>Test Connection</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.devices.map(dev => <DeviceRow key={`dev-${dev.id}`} data={dev} refreshData={refreshData} />)
                        }
                    </tbody>
                </table>
            </div>
            {
                urlData != undefined ? (<LinkSection urlData={urlData} id={data.id} />) : null
            }
        </div>
    )
}