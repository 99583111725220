/**
	_   __           
   / | / /___ __   __
  /  |/ / __ `/ | / /
 / /|  / /_/ /| |/ / 
/_/ |_/\__,_/ |___/  
					 
 * 
 * Ken Lipke
 * April 2021
 * 
 * Navbar for simple email app navigation between pages
 */

// import { Nav, Navbar } from "react-bootstrap";
import { CollectionsOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { NavLink, useLocation } from 'react-router-dom';

export default function MyNavBar(props) {

	const [expanded , setExpanded] = useState(false);
	const [resizeState, setResizeState] = useState(0);

	const location = useLocation();
	const { pathname } = location;
	const page = pathname.split('/')[1];

	const history = useHistory();
	const { logout, currentUser } = useAuth();

	useEffect(() => {
		const updateState = () => {
			console.log("HERE");
			setResizeState(Math.random())
		}
		window.addEventListener('resize', updateState);
		return () => window.removeEventListener('resize', updateState);
	}, [])

	const goTo = (which) => {
		let current = window.location.pathname;
		if (which == current) {
			return;
		} else {
			history.push(which);
		}
	};

	const isExpanded = () => {
		return expanded || (window.innerWidth >= 1024);
	}

	return ( currentUser.email === 'giglesia@avsservices.es' || currentUser.email === 'systemsupport@avsinvestigation.es' ?
		<nav className="flex items-center justify-between flex-wrap bg-blue-500 p-6">
			<div className="flex items-center flex-shrink-0 text-white mr-6">
				<span className="font-semibold text-xl tracking-tight">Hellometer Admin Tool</span>
			</div>
			<div className='block lg:hidden'>
				<button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
					onClick={() => setExpanded(!expanded)}>
					<svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
				</button>
			</div>
			{isExpanded() ? (
				<div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
					<div className="text-sm lg:flex-grow">
				
						<NavLink exact to="/customer" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'customer' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Customers</span>
							</div>
						</NavLink>
					</div>
					<div className='mt-4 pt-2 border-t border-gray-100 lg:border-none lg:mt-0 lg:pt-0'>
						{currentUser ? currentUser.email : "No One"}
						<button className='ml-2 text-gray-800 hover:text-white'
							onClick={logout}>
							Signout
						</button>
					</div>
				</div>
			) : null}

		</nav>
	:
		<nav className="flex items-center justify-between flex-wrap bg-blue-500 p-6">
			<div className="flex items-center flex-shrink-0 text-white mr-6">
				<span className="font-semibold text-xl tracking-tight">Hellometer Admin Tool</span>
			</div>
			<div className='block lg:hidden'>
				<button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
					onClick={() => setExpanded(!expanded)}>
					<svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
				</button>
			</div>
			{isExpanded() ? (
				<div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
					<div className="text-sm lg:flex-grow">
						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/")}>
							Emails
						</a> */}
						
						<NavLink exact to="/emaileditor" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === '' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Emails</span>
							</div>
						</NavLink>

						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/emails")}>
							New (Beta) Emails
						</a> */}

						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/data")}>
							Data
						</a> */}

						<NavLink exact to="/data" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'data' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Data</span>
							</div>
						</NavLink>

						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/customer")}>
							Customers
						</a> */}

						<NavLink exact to="/customer" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'customer' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Customers</span>
							</div>
						</NavLink>

						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/rebooter")}>
							Rebooter
						</a> */}

						<NavLink exact to="/downtracker" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'downtracker' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">DownTracker</span>
							</div>
						</NavLink>

						<NavLink exact to="/eudowntracker" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'eudowntracker' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">EU DownTracker</span>
							</div>
						</NavLink>

						<NavLink exact to="/rebooter" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'rebooter' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Rebooter</span>
							</div>
						</NavLink>

						{/* <NavLink exact to="/deployer" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'deployer' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Deployer</span>
							</div>
						</NavLink> */}

						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/links")}>
							Links
						</a> */}

						<NavLink exact to="/links" className={`block mt-4 lg:inline-block lg:mt-0 mr-4 cursor-pointer transition duration-150 hover:text-white ${page === 'links' && 'font-bold'}`}>
							<div className="flex flex-grow">
								<span className="text-sm">Links</span>
							</div>
						</NavLink>


						{/* <a className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
							onClick={() => goTo("/help")}>
							Help
						</a> */}
					</div>
					<div className='mt-4 pt-2 border-t border-gray-100 lg:border-none lg:mt-0 lg:pt-0'>
						{currentUser ? currentUser.email : "No One"}
						<button className='ml-2 text-gray-800 hover:text-white'
							onClick={logout}>
							Signout
						</button>
					</div>
				</div>
			) : null}

		</nav>
	);

	// 	return (
	// 		<Navbar bg="light">
	// 			<Navbar.Brand href="#">Hellometer Emailer</Navbar.Brand>
	// 			{/* <Nav.Link onClick={() => goTo('/')}>
	// 				Sender
	// 			</Nav.Link> */}
	// <Nav.Link  onClick={() => goTo('/')}>
	// 				Editor
	// 			</Nav.Link>
	// 			<Nav.Link  onClick={() => goTo('/help')}>
	// 				Help
	// 			</Nav.Link>

	// 			<Navbar.Collapse className="justify-content-end">
	// 				<Navbar.Text>
	// 					{currentUser ? currentUser.email : 'No one'}
	// 				</Navbar.Text>
	// 				<Nav.Link  onClick={logout}>
	// 					Signout
	// 				</Nav.Link>
	// 			</Navbar.Collapse>
	// 			{/* <Nav.link>
	// 				Signout
	// 			</Nav.link> */}
	// 		</Navbar>
	// 	);
}
