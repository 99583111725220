import React, { useState } from 'react';

export default function LinkSection({urlData, id}){
	
	const [open, setOpen] = useState(false);

	return (
		<>
			<br/>
			<div className='pt-5'>
				<h2 className='text-bold text-lg cursor-pointer'
					onClick={() => setOpen(!open)}>
					Cameras & Links &nbsp;
					{open ? <>&#9661;</> : <>&#9651;</>}
				</h2>
				{
					open ? (
						<table className='w-full'>
							<tbody>
								{
									Object.keys(urlData).includes("recovery_server") ? (
										<tr className='hover:bg-gray-300'>
											<td>Recovery</td>
											<td className='text-right'>
												<a
													href={urlData['recovery_server']}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													{urlData['recovery_server']}
												</a>
											</td>
										</tr>
									) : null
								}
								{
									Object.keys(urlData).includes("dt_monitor") ? (
										<tr className='hover:bg-gray-300'>
											<td>Drive Thru</td>
											<td className='text-right'>
												<a
													href={urlData['dt_monitor']}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													{urlData['dt_monitor']}
												</a>
											</td>
										</tr>
									) : null
								}
								{
									Object.keys(urlData).includes("video_monitor") ? (
										<tr className='hover:bg-gray-300'>
											<td>Go Video</td>
											<td className='text-right'>
												<a
													href={urlData['video_monitor']}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													{urlData['video_monitor']}
												</a>
											</td>
										</tr>
									) : null
								}
								{
									(
										<tr className='hover:bg-gray-300'>
											<td>Recovery OpenVPN</td>
											<td className='text-right'>
												<a
													href={`http://pc.${id}.hellometer.openvpn.com:3000`}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													http://pc.{id}.hellometer.openvpn.com:3000
												</a>
											</td>
										</tr>
									)
								}
								{
									(
										<tr className='hover:bg-gray-300'>
											<td>Drive Thru OpenVPN</td>
											<td className='text-right'>
												<a
													href={`http://pc.${id}.hellometer.openvpn.com:5005`}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													http://pc.{id}.hellometer.openvpn.com:5005
												</a>
											</td>
										</tr>
									)
								}
								{
									(
										<tr className='hover:bg-gray-300'>
											<td>Go Video OpenVPN</td>
											<td className='text-right'>
												<a
													href={`http://pc.${id}.hellometer.openvpn.com:8083`}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													http://pc.{id}.hellometer.openvpn.com:8083
												</a>
											</td>
										</tr>
									)
								}
								{
									urlData.cameras.map((cam, i) => {
										return (
											<tr className='hover:bg-gray-300' key={i}>
												<td>Camera {i}</td>
												<td className='text-right'>
													<a
														href={cam}
														target="_blank"
														className='text-blue-500 hover:underline'
														>
														{cam}
													</a>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					) : null
				}
			</div>
		</>
	)
}