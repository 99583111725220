import React, { useState, useEffect } from "react";
import { useDataContext } from "../DataContext";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddLocationModal from "./AddLocationModal";

export default function CustomerLocationList({
	customerData,
	reloadCustomerData,
}) {
	const [customerLocationData, setCustomerLocationData] = useState();
	const [showAddLocationModal, setShowAddLocationModal] = useState(false);

	const { routes } = useDataContext();

	useEffect(() => {
		console.log("Customer data updated, make call for location");
		getCustomerLocationData();
	}, [customerData]);

	const getCustomerLocationData = () => {
		let params = {
			ids: customerData.firestore.locations,
		};
		let url = routes["locationData"];
		fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				setCustomerLocationData(data);
			})
			.catch((e) => {
				console.warn(e);
				window.alert("Had an issue getting customer location data");
			});
	};

	const updatePrimaryLocation = (id) => {
		console.log("New primary Location", id);
		let params = {
			uid: customerData.auth.uid,
			primary_location: id,
		};
		let url = routes["updateUserFirestore"];
		fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			// .then(() => reloadCustomerData())
			.catch((err) => {
				console.warn(err);
				window.alert("Had an issue updating primary location...");
			});
	};

	const removeLocation = (id) => {
		let locs = customerData.firestore.locations.filter(x => x != id);
		let params = {uid: customerData.auth.uid, locations:locs}
		let url = routes['updateUserFirestore'];
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => {
			reloadCustomerData();
		})
		.catch(err => {
			console.warn(err);
			window.alert("Error removing location");
		})
	}

	const addLocations = (ids) => {
		// Create new location array for customer
		console.log(customerData.firestore.locations);
		let currentLocations = customerData.firestore.locations;
		let allLocations = currentLocations.concat(ids);
		let params = {uid: customerData.auth.uid, locations:allLocations}
		let url = routes['updateUserFirestore'];
		console.log(params);
		// return;
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => {
			reloadCustomerData();
		})
		.catch(err => {
			console.warn(err);
			window.alert("Error adding location");
		})
	}

	return (
		<>
		<div>
			<div className="text-lg font-bold">Locations</div>
			<div>
				Primary Location:
				{customerLocationData != undefined ? (
					<select
						// value={customerData.firestore.primary_location}
						onChange={(e) => updatePrimaryLocation(e.target.value)}
						className="border-2 px-2 py-1 ml-2 rounded-lg border-gray-400">
						{customerLocationData
							.sort((a, b) => a.id - b.id)
							.map((loc) => {
								return (
									<option
										key={loc.address}
										value={loc.id}
										selected={
											loc.id == customerData.firestore.primary_location
										}>
										{loc.id}. {loc.address} ({loc.display_name})
									</option>
								);
							})}
					</select>
				) : (
					<span className='text-gray-500 animate-pulse'>&nbsp; Loading...</span>
				)}
			</div>
			<div className="mt-2">
				All Locations
				{customerLocationData != undefined ? (
					<div>
						<table className='table table-auto w-full'>
							<thead className='bg-gray-300 text-gray-600'>
								<tr className='text-left'>
									<th>id</th>
									<th>Address</th>
									<th>Display Name</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
								{
									customerLocationData.map(x => {
										return (
											<tr key={`row-${x.address}`}
												className='even:bg-gray-200 hover:bg-blue-200'>
												<td>
													{x.id}
												</td>
												<td className='pl-2'>
													{x.address}
												</td>
												<td>
													{x.display_name}
												</td>
												<td
													onClick={() => removeLocation(x.id)}
													className='cursor-pointer text-center hover:text-red-500'>
													<HighlightOffIcon />
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				) : (
					<span className='text-gray-500 animate-pulse'>&nbsp; Loading...</span>
				)}
			</div>

			<div className='mt-4 flex justify-center'>
				<button
				 	onClick={() => setShowAddLocationModal(true)}
					className='btn border-2 p-2 rounded-lg w-1/2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white'>
					Add Location
				</button>
			</div>
		</div>
		{showAddLocationModal ? (
			<AddLocationModal 
				closeModal={() => setShowAddLocationModal(false)}
				currentLocations={customerData.firestore.locations}
				addLocations={addLocations}/>

		) : null}
		</>

	);
}
