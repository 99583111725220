/**
	______                _ ______                 _             
   / ____/___ ___  ____ _(_) / __ \________ _   __(_)__ _      __
  / __/ / __ `__ \/ __ `/ / / /_/ / ___/ _ \ | / / / _ \ | /| / /
 / /___/ / / / / / /_/ / / / ____/ /  /  __/ |/ / /  __/ |/ |/ / 
/_____/_/ /_/ /_/\__,_/_/_/_/   /_/   \___/|___/_/\___/|__/|__/  
																 
 * Ken Lipke
 * April 2021
 * 
 * Preivews the email for the given location
 * Has a loder if there isn't one, or displays a message
 */

import React, { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Spinner from 'react-bootstrap/Spinner';

export default function EmailPreview(props){

	// Random state to trigger refresh of iFrame
	const [randState, setRandState] = useState(0);

	// // On Location ID change
	// useEffect( () => {
	// 	// Get the date
	// 	let url = getPreview();
	// 	setUrl(url);

	// }, [props.location_id]);

	const showPreview = () => {
		// let url = getPreview();
		let url = props.getURL();
		url = url + "?rand=" + Math.floor(Math.random()*10000);
		console.log(url);
		if (props.canBuild){
			return (
				<>
				{/* <iframe src={url} width={'850px'} height={'1100px'}/> */}
				<iframe src={url} className='w-full' height={'1000px'}/>
				<button className='btn rounded-sm p-3 ml-3 border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white' 
					onClick={refresh}>
					Refresh Preview
				</button>
				</>
			);
		}
		else{
			return (
				<h2>Building... </h2>
			)
		}
	}
	const refresh =() => {
		// Set random
		console.log("refreshing");
		setRandState(Math.random());
	}


	return(
		<>
			{/* <iframe src={url} width={'800px'} height={'900px'}/> */}
			{showPreview()}
		</>
	)
}