import React, { useState, useEffect } from 'react';
import MyNavBar from '../MyNavBar';
import { useDataContext } from '../DataContext';
export default function Deployer(props){
    
    const [port, setPort] = useState(80);
    const [host, setHost] = useState('3.tcp.ngrok.io');
    const [deploying, setDeploying] = useState(false);

    const { routes } = useDataContext();

    const deploy = () => {
        let url = routes['getAllLocations'];
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                
            })
    }

    return (
        <>
            <MyNavBar />
            <div className='p-16 my-8 grid grid-cols-2 gap-4 content-center '>
                <input autoFocus type='text' value={host} onChange={(e) => setHost(e.target.value)} />
                <input type='number' value={port} onChange={(e) => setPort(e.target.value)} />
            </div>
            <div className='p-16 my-8 grid grid-cols-1 gap-1 content-center '>
                <button className="btn border p-1 rounded-lg text-xl border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white">Deploy</button>
            </div>
        </>
    )
}