import React from "react";

export default function LocationSelector({
	allLocations,
	selectedLocation,
	setSelectedLocation,
}) {

    /**
     * Updates the selected location by id
     * @param {int} id Location id
     */
    const updateLocation = (id) => {
        let loc = allLocations.filter(x => x.id == id)[0];
        setSelectedLocation(loc);
    }

	return (allLocations === undefined || selectedLocation === undefined) ? <>Loading...</> : (
		<form>
			<label>Select Location</label>
			<select
                value={selectedLocation.id}
                onChange={(e) => updateLocation(e.target.value)}
                className='border border-gray-300 shoadow-sm rounded-lg ml-2 px-2 py-1'
                >
                {
                    allLocations.map(loc => {
                        return (
                            <option value={loc.id} key={loc.id}>
                                {loc.display_name}  (id: {loc.id})
                            </option>
                        )
                    })
                }
            </select>
		</form>
	);
}
