import React, { useState, useEffect } from 'react';
import { useDataContext } from '../DataContext';
import MyNavBar from '../MyNavBar';
import CreateUser from './CreateUser';
import CustomerInfo from './CustomerInfo';
import CustomerList from './CustomerList';
import CustomerPlaceholder from './CustomerPlaceholder';
import { useAuth } from '../AuthContext';

export default function CustomerAdminPage(props){

	const [selectedCustomer, setSelectedCustomer] = useState();
	const [customerList, setCustomerList] = useState();
	const [creatingAccount, setCreatingAccount] = useState(false);
	const [customerData, setCustomerData] = useState();

	// const APIPrefix = "http://localhost:5001/"
	const { routes } = useDataContext();
	const { currentUser } = useAuth();

	/**
	 * Load the customer list
	 */
	useEffect(() => {
		console.log(currentUser.email);
		getCustomerList();
	}, []);

	useEffect(() => {
		if (selectedCustomer != undefined){
			getCustomerData();
		}
	}, [selectedCustomer])

	const getCustomerList = () => {
		setCustomerList(undefined);
		// let url = APIPrefix + "customer/getAllUsersFB";
		let url = routes['getAllUsers'];
		fetch(url)
			.then(res => res.json())
			.then(data => {
				if (currentUser.email === 'giglesia@avsservices.es' || currentUser.email === 'systemsupport@avsinvestigation.es') {
					setCustomerList(data.filter(user => ['areas', 'brisa', 'lagardere', 'avs', 'luisdelgadopuig'].some(val => user.email.includes(val))))
				}
				else {
					setCustomerList(data)
				}
			});
	}

	const getCustomerData = () => {
		// Gets data for selected customer
		setCustomerData(undefined);
		// let url = APIPrefix + "customer/getUserData"
		let url = routes['getUserData'];
		fetch(url, {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({'uid': selectedCustomer})
		})
		.then(res => res.json())
		// .then(data => console.log(data))
		.then(data => {
			console.log("Customer Data Fetched", data);
			setCustomerData(data);
		})
		.catch((e) => {
			console.warn(e);
			alert("Had an issue getting data, try refreshing the page");
		})
	}

	/**
	 * Renders the customer side according to:
	 * - if no selected customer and not creating account --> placeholder
	 * - if creating account (no matter selected customer) --> create account
	 * - if not creating account and customer selected --> customer info
	 */
	const renderCustomerSide = () => {
		console.log(selectedCustomer);
		console.log(creatingAccount);
		if ( (selectedCustomer == undefined) & (!creatingAccount) ){
			return (
				<CustomerPlaceholder 
					setCreatingAccount={setCreatingAccount}/>
			)
		}
		else if (creatingAccount){
			return(
				<CreateUser 
					setCreatingAccount={setCreatingAccount}
					setSelectedCustomer={setSelectedCustomer}/>
			)
		}
		else if ( (!creatingAccount) & (selectedCustomer != undefined) ){
			return (
				<CustomerInfo 
					customerData={customerData}
					setCreatingAccount={setCreatingAccount}
					setSelectedCustomer={setSelectedCustomer}
					reloadCustomerData={getCustomerData}/>
			)
		}
		else{
			return (
				<CustomerPlaceholder 
					setCreatingAccount={setCreatingAccount}/>
			)
		}
	}

	return (
		<>
			<MyNavBar />
			<div className='grid grid-cols-1 sm:grid-cols-2 gap-4 p-3 mt-4'>
				<div>
					<CustomerList 
						customerList={customerList}
						getCustomerList={getCustomerList}
						setSelectedCustomer={setSelectedCustomer}/>
				</div>
				<div className=''>
					{renderCustomerSide()}
				</div>
			</div>
		</>
	)
}