import React, { useState } from 'react';

export default function LinkSection({urlData}){
	
	const [open, setOpen] = useState(false);

	return (
		<>
			<br/>
			<div className='pt-5'>
				<h2 className='text-bold text-lg cursor-pointer'
					onClick={() => setOpen(!open)}>
					Cameras & Links &nbsp;
					{open ? <>&#9661;</> : <>&#9651;</>}
				</h2>
				{
					open ? (
						<table className='w-full'>
							{
								Object.keys(urlData).includes("dt_monitor") ? (
									<tr className='hover:bg-gray-300'>
										<td>Drive Thru</td>
										<td className='text-right'>
											<a
												href={urlData['dt_monitor']}
												target="_blank"
												className='text-blue-500 hover:underline'
												>
												{urlData['dt_monitor']}
											</a>
										</td>
									</tr>
								) : null
							}
							{
								Object.keys(urlData).includes("video_monitor") ? (
									<tr className='hover:bg-gray-300'>
										<td>Go Video</td>
										<td className='text-right'>
											<a
												href={urlData['video_monitor']}
												target="_blank"
												className='text-blue-500 hover:underline'
												>
												{urlData['video_monitor']}
											</a>
										</td>
									</tr>
								) : null
							}
							{
								urlData.cameras.map((cam, i) => {
									return (
										<tr className='hover:bg-gray-300' key={i}>
											<td>Camera {i}</td>
											<td className='text-right'>
												<a
													href={cam}
													target="_blank"
													className='text-blue-500 hover:underline'
													>
													{cam}
												</a>
											</td>
										</tr>
									)
								})
							}
						</table>
					) : null
				}
			</div>
		</>
	)
}