import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from './AuthContext';


export default function PrivateRoute({ component: Component, ...rest}) {
    const { currentUser } = useAuth();

    const allowed = ['ken@hellometer.io', 
        'alex@hellometer.io',
        'javi@hellometer.io',
        'alexandrea@hellometer.io',
        'alberto@hellometer.io',
        'alicia@hellometer.io', 
        'web@test.com',
        'giglesia@avsservices.es',
        'systemsupport@avsinvestigation.es'
    ];
    const allowedUser = () => {
        if (currentUser && allowed.indexOf(currentUser.email) != -1){
            return true;
        }
        else{
            return false;
        }
    }

    return (
        <Route
            {...rest}
            render={props => {
                return allowedUser() ? <Component {...props} {...rest} /> : <Redirect to='/login'/>
            }}
        ></Route>
    )
}
