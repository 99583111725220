import React from "react";

export default function BasicModal({ children, id, closeModal, title, accept, acceptText }) {
	return (
		<div
			className="overflow-y-auto overflow-x-hidden h-screen w-screen fixed right-0 left-0 top-4 z-50 flex justify-center items-center md:inset-0 bg-gray-700 bg-opacity-50">
			<div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
				{/* <!-- Modal content --> */}
				<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
					{/* <!-- Modal header --> */}
					<div className="flex justify-between items-start p-3 rounded-t border-b dark:border-gray-600">
						<h3 className='font-bold text-lg'>
                            {title}
                        </h3>
					</div>
					{/* <!-- Modal body --> */}
					<div className="p-6 space-y-6">
                        {children}
					</div>
					{/* <!-- Modal footer --> */}
					<div className="flex w-full flex-row-reverse p-3 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
						<button
                            onClick={closeModal}
                            className='btn bg-red-500 text-white hover:bg-red-700 px-3 py-1 rounded-lg m-1'>
                            Cancel
                        </button>
                        <button
                            onClick={accept}
                            className='btn bg-green-500 text-white hover:bg-green-700 px-3 py-1 rounded-lg m-1'>
                            {acceptText}
                        </button>
					</div>
				</div>
			</div>
		</div>
	);
}
