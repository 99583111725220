import React, { useState, useEffect } from "react";
import MyNavBar from "../MyNavBar";
import { useDataContext } from "../DataContext";
import dateFormat from "dateformat";
import LocationSelector from "./LocationSelector";
import DateSelector from "./DateSelector";
import { FormatIndentDecreaseTwoTone } from "@material-ui/icons";
import DataTable from "./DataTable";
import AddDetectionModal from "./AddDetectionModal";
import TimeSpentFilter from "./TimeSpentFilter";


export default function DataEditor() {
	const { routes } = useDataContext();

	const [allLocations, setAllLocations] = useState();
	const [location, setLocation] = useState();
	// const [date, setDate] = useState(new Date("2021-12-28")); // hard coded of rnow
	const [date, setDate] = useState(sessionStorage.getItem("dataDate") ?
		 new Date(parseInt(sessionStorage.getItem("dataDate")))
		 : new Date());
	// const [data, setData] = useState();
    const [detectionData, setDetectionData] = useState();
    const [regionData, setRegionData] = useState();
	const [showAddDetection, setShowAddDetection] = useState(false);
	const [filterRange, setFilterRange] = useState([30, 1200]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [showDeleted, setShowDeleted] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const [toDelete, setToDelete] = useState([]);
	const [dtFilter, setDtFilter] = useState('all');

	const testLocation = {
		id: 14,
		display_name: "Test Location",
		time_zone: "America/Chicago",
	};

	// Get All Locations on Load
	useEffect(() => {
		let url = routes["getAllLocations"];
		fetch(url)
			.then((res) => res.json())
			.then((data) => {
				data.push(testLocation);
				data.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
				setAllLocations(data);
				let localLocation = localStorage.getItem("dataLocation");
				if (localLocation != undefined){
					// if it is one of the allowed locations, set it
					localLocation = parseInt(localLocation);
					if (data.map(x => x.id).includes(localLocation)){
						setLocation(data.filter(x => x.id == localLocation)[0])
					}
					else{
						setLocation(data[0]);
					}
				}
				else{
					setLocation(data[0]);
				}
				// setLocation(data[0]);
				// setLocation(testLocation);
			})
			.catch((err) => console.warn(err));
	}, []);

	// Get detection data for location and date
	useEffect(() => {
		if (location !== undefined && date !== undefined) {
            // setData(undefined);
			// getData();
            setDetectionData();
            setRegionData();
            getData();
		}
	}, [location, date]);

	// Save selected location to localStorage
	useEffect(() => {
		if (location !== undefined){
			localStorage.setItem("dataLocation", location.id)
		}
	}, [location])

	// Save date to session storage
	useEffect(() => {
		if (date !== undefined){
			sessionStorage.setItem("dataDate", date.getTime());
		}
	}, [date])

	// When toggling deleteMode, clear what is to be deleted
	useEffect(() => {
		setToDelete([]);
	}, [deleteMode])


	const getData = () => {
		let url = routes["getDBData"];
		let params = {
			location_id: location.id,
			start_date: dateFormat(date, "isoDate"),
			end_date: dateFormat(date, "isoDate"),
		};
		fetch(url, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			// .then((data) => setData(data))
            .then((data) => {
                setDetectionData(data['detection']);
                setRegionData(data['regions']);
            })
			.catch((err) => console.warn(err));
	};


	const getDetectionData = () => {
		if (detectionData === undefined){
			return undefined;
		}
		return detectionData.filter(x => {
			let ind = true;
			// time filter
			if (isFiltered){
				ind = ind & (x.time_spent >= filterRange[0] && x.time_spent <= filterRange[1]);
			}
			if (!showDeleted){
				ind = ind & (x.model_id != -7)
			}
			return ind;
		});
	}

	const batchDelete = () => {
		let r = window.confirm("Are you sure? This will take a moment so hold tight");
		if (!r){return}

		// do delete logic here
		console.log(toDelete);
		let url = routes['batchDeleteDetection'];
		let params = {
			'ids': toDelete
		}
		fetch(url, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
		.then(res => {
			window.alert("Batch delete successful, gives us a moment to reload");
			setToDelete([]);
			setDeleteMode(false);
			getData();
		}).catch(err => {
			window.alert("Error deleteing entries, try again");
			// getData();
		})
	}

	return (
		<>
			<MyNavBar />
			<div className="p-4">
				<div className="flex flex-wrap flex-row gap-4">
					<LocationSelector
						allLocations={allLocations}
						selectedLocation={location}
						setSelectedLocation={setLocation}
					/>

					<DateSelector date={date} setDate={setDate} />

                    <button className='btn border text-sm border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white p-2 rounded-lg'
                        onClick={() => {
                            setDetectionData();
                            setRegionData();
                            getData();
                        } }>
                        Refresh Data
                    </button>

				</div>

				<div className='flex flex-wrap gap-4 mt-2'>
					<TimeSpentFilter 
						filterRange={filterRange}
						setFilterRange={setFilterRange}
						isFiltered={isFiltered}
						setIsFiltered={setIsFiltered}/>

					<div>
						Show Deleted 
						<input
							className='ml-2'
							type='checkbox'
							checked={showDeleted}
							onChange={() => setShowDeleted(!showDeleted)}
							/>
					</div>

					<div>
						<span className='text-red-500'>
							Delete Mode
						</span>
						<input
							className='ml-2 bg-white text-red-500'
							type='checkbox'
							checked={deleteMode}
							onChange={() => setDeleteMode(!deleteMode)}
							/>
						{
							deleteMode ? (
								<button
									className='btn m-1 px-2 py-1 bg-red-400 text-white hover:bg-red-600 rounded-sm'
									onClick={batchDelete}
									>
									Delete Selected
								</button>
							) : null
						}
					</div>
					<div className='divide-x'>
						<button 
							className={`btn px-2 py-1 rounded-l-sm bg-gray-300 ${dtFilter=='all' ? 'bg-gray-500 text-white' : ''}`}
							onClick={() => setDtFilter('all')}>
							All
						</button>
						<button 
							className={`btn px-2 py-1 bg-gray-300 ${dtFilter=='lobby' ? 'bg-gray-500 text-white' : ''}`}
							onClick={() => setDtFilter('lobby')}>
							Lobby
						</button>
						<button 
							className={`btn px-2 py-1 rounded-r-sm bg-gray-300 ${dtFilter=='dt' ? 'bg-gray-500 text-white' : ''}`}
							onClick={() => setDtFilter('dt')}>
							Drive-Thru
						</button>
					</div>
				</div>

				{/* note, this used to have flex as a class */}
				<div className="overflow-auto mt-4 max-h-80 max-w-7xl">
                    <DataTable 
						detectionData={getDetectionData()}
						deleteMode={deleteMode}
						toDelete={toDelete}
						setToDelete={setToDelete}
						// detectionData={isFiltered && detectionData !== undefined ? detectionData.filter(x => x.time_spent >= filterRange[0] && x.time_spent <= filterRange[1]) : detectionData} 
						regionData={regionData} 
						locationData={location} 
						getData={getData}
						dtFilter={dtFilter}/>
				</div>

                <div className='mt-8'>
                    <button className='btn bg-green-500 text-white hover:bg-green-700 rounded-lg px-4 py-2'
						onClick={() => setShowAddDetection(true)}>
                        Add Detection
                    </button>
                </div>

				{
					showAddDetection ? 
					<AddDetectionModal 
						setShow={setShowAddDetection}
						locationData={location}
						refresh={getData}/> 
					: null
				}

			</div>
		</>
	);
}
