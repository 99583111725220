/**
	____        __       _    ___                       
   / __ \____ _/ /_____ | |  / (_)__ _      _____  _____
  / / / / __ `/ __/ __ `/ | / / / _ \ | /| / / _ \/ ___/
 / /_/ / /_/ / /_/ /_/ /| |/ / /  __/ |/ |/ /  __/ /    
/_____/\__,_/\__/\__,_/ |___/_/\___/|__/|__/\___/_/     
														
 * Ken Lipke
 * April 2021
 * 
 * Querries and gets the Data for the given location
 */

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import TextField from '@material-ui/core/TextField';
import dateformat from 'dateformat';
import { ExpandLessSharp, KeyboardReturnOutlined } from '@material-ui/icons';

export default function DataViewer(props){

	const [detectionData, setDetectionData] = useState([]);

	let y = new Date(); y.setDate(y.getDate()-1);
	// let _d = new Date(); _d.setMonth(0); _d.setDate(26);
	const [dbDate, setDBDate] = useState(y); // init as yesterday

	useEffect(() => {
		getData();
	}, [props.location_id, dbDate, props.viewTrigger])

	/**
	 * Gets the detections for yesterday for the current locaiton
	 */
	const getData = () => {
		// if ( (props.location_id == "summary") | (props.location_id == 'roundup') ){return}
		if (['summary', 'summary_dq', 'roundup'].includes(props.location_id)){return}

		// Set no data while it loads
		setDetectionData([]);

		// get start and end timestamp based on dbDate
		let dateBounds = getStartEnd();

		// Define params
		let params = {
			'location_id': props.location_id,
			'date': formatDate(dbDate)
		};

		let url = "https://api-iaily4lvlq-uc.a.run.app/detection/getYesterday";
		// let url = "http://localhost:5001/detection/getYesterday";
		fetch(url, {
				method:'POST',
				// mode:'cors',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(params)
			})
			.then(r => r.json())
			// .then(d => setDetectionData(d))
			.then(d => {
				console.log(d);
				setDetectionData(d);
			})
			.catch(e => console.error(e))
	}


	const getStartEnd = () => {
		// get start of day
		let d = formatDate(dbDate).split('-')
		let s = new Date(d[0], d[1], d[2]);
		let e = new Date(d[0], d[1], d[2]);
		e.setDate(e.getDate() + 1);

		return [s, e]
	}

	const formatDate = (d) => {
		return dateformat(d, "yyyy-mm-dd");
	}

	const handleDateChange = (e) => {
		console.log(e.target.value);
		let date_parts = e.target.value.split('-')
		let newDate = new Date(date_parts[0], date_parts[1]-1, date_parts[2]);
		console.log(newDate);
		setDBDate(newDate);
	}

	const handleDelete = (id) => {
		console.log("Delete Id:", id);
		let v = window.confirm(`Are you sure you want to delete id ${id}, this cannot be undone`);
		if (!v){
			console.log("Delete aborted");
			return;
		}

		let v2 = window.confirm("Are you ABSOLUTELY sure??");
		if (!v2){
			console.log("Delete aborted");
			return;
		}
		console.log("Delete confirmed");

		// Make call to delete
		// let url = "http://localhost:5000/detection/dropDetection";
		let url = "https://api-iaily4lvlq-uc.a.run.app/detection/dropDetection";
		let params = {'id':id}
		fetch(url, {
			method: 'post',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify(params)
		})
		.then(r => {
			getData();
		})
		.catch(e => {
			console.warn(e);
			window.alert("There was an issue, item was likely not deleted");
			getData();
		});

	}

	/**
	 * Render helper to create a table based on the
	 * detectionData state
	 */
	const makeTable = () => {
		if (['summary',  'summary_dq', 'roundup'].includes(props.location_id)){
		// if ( (props.location_id == "summary") | (props.location_id == 'roundup') ){
			return <h3>Cannot show data for summary</h3>
		}
		if (detectionData.length == 0){
			return <Spinner animation='border' variant='primary' />
		}

		// determine the headers
		let headers = Object.keys(detectionData[0]);

		
		// Make the table
		return (
			// <div style={{'maxHeight': '800px', 'overflow':'auto', 'width': '80%', 'maxWidth': '1200px'}}>
			<div className='max-h-80 w-full max-w-3xl mt-2 overflow-auto' style={{'maxHeight':'800px'}}>
				<table className='max-h-96'>
					<thead className='bg-gray-400'>
						<tr>
							{
								headers.map(col => <th key={col}>{col}</th>)
							}
							<th>
								Delete
							</th>
						</tr>
					</thead>
					<tbody>
						{
							detectionData.map( (d, j) => {
								return (
									<tr key={j} className='even:bg-gray-100'>
										{/* {Object.keys(d).map( (k, i) => <td key={i}>{JSON.stringify(d[k])}</td>)} */}
										{Object.keys(d).map( (k, i) => <td key={i}>{renderTableValue(d[k])}</td>)}
										<td key={'del'} style={{'textAlign':'center'}}>
											<IconButton style={{'backgroundColor':'None'}}
												onClick={() => handleDelete(d['id'])}>
												<DeleteIcon color='secondary'/>
											</IconButton>
										</td>
									</tr>
							)
							})
						}
					</tbody>
				</table>
			</div>
		)
	}

	const renderTableValue = (val) => {
		if (typeof(val) == 'boolean'){
			return val ? <span>&#128663;</span> : <span>&#128694;</span>
		}
		else{
			return val;
		}
	}

	return(
		<div className='mt-8 flex flex-col flex-1 justify-center items-center'>
			<div className='d-flex justify-content-center'>
				<div className='text-lg'>Database Viewer</div>
			</div>
			<div className='d-flex justify-content-center'>
				<TextField
					id='dbDate'
					label='Date'
					type='date' 
					defaultValue={formatDate(dbDate)}
					onChange={handleDateChange}/>
			</div>
			<div className='d-flex  justify-content-center'>
				{makeTable()}
			</div>
			<div>
				<button 
					className='btn border-2 p-2 border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white rounded-lg'
					variant='outline-primary'
					onClick={getData}>
					Refresh
				</button>
			</div>
		</div>
	)
}