import React from "react";

export default function Actions({
	editMode,
	setEditMode,
	deleteDetection,
	expanded,
	setExpanded,
	save
}) {
	return !editMode ? (
		<>
			{
				expanded != null ? (
					<button
						className="cursor-pointer hover:bg-yellow-300"
						onClick={() => setExpanded(!expanded)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-caret-down"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#9e9e9e"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
						</svg>
					</button>
				) : null
			}

			<button
				className="cursor-pointer hover:bg-yellow-300"
				onClick={() => setEditMode(true)}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="icon icon-tabler icon-tabler-pencil"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="#00b341"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
					<line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
				</svg>
			</button>

			<button
				className="cursor-pointer hover:bg-yellow-300"
				onClick={deleteDetection}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="icon icon-tabler icon-tabler-trash"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="#ff2825"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<line x1="4" y1="7" x2="20" y2="7" />
					<line x1="10" y1="11" x2="10" y2="17" />
					<line x1="14" y1="11" x2="14" y2="17" />
					<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
					<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
				</svg>
			</button>
		</>
	) : (
		<>
			<button
				className="cursor-pointer hover:bg-red-300"
				onClick={() => setEditMode(false)}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="icon icon-tabler icon-tabler-circle-x"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="#ff2825"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<circle cx="12" cy="12" r="9" />
					<path d="M10 10l4 4m0 -4l-4 4" />
				</svg>
			</button>
			<button
				className="cursor-pointer hover:bg-green-300"
				onClick={save}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="icon icon-tabler icon-tabler-device-floppy"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="#00abfb"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
					<circle cx="12" cy="14" r="2" />
					<polyline points="14 4 14 8 8 8 8 4" />
				</svg>
			</button>
		</>
	);
}
