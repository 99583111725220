

import React, { useState, useEffect, createRef } from 'react';
import { Row, Col, Table, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import dateformat from 'dateformat';
import Papa from 'papaparse';

export default function DataUploader(props) {

	const [csvText, setCSVText] = useState('');
	const [canUpload, setCanUpload] = useState(true);
	const [downloadLink, setDownloadLink] = useState();
	const [uploadType, setUploadType] = useState('timestamp');
	const [isDriveThru, setIsDriveThru] = useState(false);
	const linkRef = createRef();

	let y = new Date(); y.setDate(y.getDate() - 1);
	const [dbDate, setDBDate] = useState(y);

	useEffect(() => {
		formatDate();
	}, [])


	const formatDate = (d) => {
		let datePart = dateformat(d, "yyyy-mm-dd");
		let timePart = dateformat(d, "HH:00");
		return datePart + "T" + timePart;
	}

	const updateDate = (e) => {
		console.log(e.target.value);
		let s = e.target.value;
		let split = s.split('T')
		let ds = split[0];
		let ts = split[1];

		// deal with date
		let dateParts = ds.split('-');
		let date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

		// deal with time
		let timeParts = ts.split(":");
		date.setHours(timeParts[0], timeParts[1]);

		setDBDate(date);
	}

	const updateUploadType = (e, val) => {
		setUploadType(val);
	}

	const clear = () => {
		setCSVText('');
	}

	const readFile = async (e) => {
		e.preventDefault();
		setCSVText('');
		const reader = new FileReader()
		reader.onload = async (e) => {
			const text = (e.target.result)
			console.log(text);
			setCSVText(text);
			// alert(text)
		};
		// console.log(e.target.files);
		if (e.target.files.length == 0) { return }
		reader.readAsText(e.target.files[0])
	}

	/**
	 * Sends file to the API where is it is loaded into the database
	 */
	const upload = () => {
		let v = window.confirm("Are you sure you want to load this into the DB?");
		if (!v) { return }

		setCanUpload(false);

		// Organize parameters
		let params = {
			'csv': csvText,
			'date': dateformat(dbDate, 'yyyy-mm-dd'),
			'hour': dateformat(dbDate, 'HH'),
			'location_id': props.location_id,
			'format': uploadType,
			'isDriveThru': isDriveThru,
		}


		// Make request
		// let url = "http://localhost:5001/region/uploadHandTranscription";
		let url = "https://api-iaily4lvlq-uc.a.run.app/region/uploadHandTranscription";
		fetch(url, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(params)
		})
			.then(r => {
				props.setViewTrigger(Math.random());
				window.alert("Upload successful");
				setCanUpload(true);
			})
			.catch(d => {
				setCanUpload(true);
				window.alert("There was an error, try again--make sure the csv is clean");
			})

	}

	const showSpinner = () => {
		if (!canUpload) {
			return (
				<Spinner
					as="span"
					animation="border"
					size="sm"
					role="status"
					aria-hidden="true"
				/>
			)
		}
	}

	const downloadExample = () => {
		let s;
		if (isDriveThru){
			s = `Car,first_seen,end_payment
,10:41:54,10:45:00`
		}
		else if (uploadType == 'seconds'){
			s = `Person,First Seen,wait time,order time,counter time,payment time,total time,video_length,video_time
	,first seen in seconds,in seconds,in seconds,in seconds,in seconds,,video length in seconds,60`;
		}
		else if (uploadType == 'timestamp'){
			s = `Person,First Seen,start order,start counter,start payment,end payment
,10:02:00 AM,10:05:00 AM,10:07:00 AM,10:08:00 AM,10:10:00 AM
,10:05:00 AM,10:06:00 AM,10:07:00 AM,10:08:00 AM,10:09:00 AM`
		}
		let blob = new Blob([s]);
		let fileDownloadUrl = URL.createObjectURL(blob);
		setDownloadLink(fileDownloadUrl);
		linkRef.current.click();
	}

	const previewCSV = () => {

		// see if we can papaparse it
		let parsed = Papa.parse(csvText);
		if (parsed.data.length == 0) { return (<></>) }

		// Show the parsed in a table for preview
		return (
			<div>
				<Row>
					<Col>
						<table striped bordered>
							<thead className='bg-gray-200 p-2'>
								<tr>
									{parsed.data[0].map(x => <th key={x}>{x}</th>)}
								</tr>
							</thead>
							<tbody>
								{
									parsed.data.slice(1).map(r => {
										return (
											<tr className='even:bg-gray-100 p-2'>
												{r.map(d => <td>{d}</td>)}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</Col>
				</Row>
				<div className='flex justify-center mt-2'>
					<button
						variant='outline-danger'
						className='px-4 py-1 border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white rounded-lg'
						onClick={upload}
						disabled={!canUpload}>
						Upload {showSpinner()}
					</button>
				</div>
			</div>
		)
	}

	const showUI = () => {
		if ( (props.location_id == "summary") || (props.location_id == 'roundup')) {
			return (
				<Row>
					<Col className='d-flex justify-content-center'>
						Cannot upload data directly for summary, pick a location first
					</Col>
				</Row>
			)
		}
		else {
			return (
				<>
					<Row className='d-flex justify-content-center'>
						<TextField
							id='dbDate'
							label='Video Date and Time'
							type='datetime-local'
							defaultValue={formatDate(dbDate)}
							onChange={updateDate}
							inputProps={{ step: 3600 }} />
						<span style={{ 'width': '30px' }}></span>

					</Row>
					<Row className='d-flex justify-content-center mt-4'>
						<input type='file' onChange={e => readFile(e)} />
						
						<ToggleButtonGroup
							value={uploadType}
							onChange={(e, val) => setUploadType(val)}
							exclusive 
							style={{'marginRight':'1em'}}>
							<ToggleButton value='timestamp'>
								Timestamp
							</ToggleButton>
							<ToggleButton value='seconds'>
								Seconds
							</ToggleButton>
						</ToggleButtonGroup>
						&nbsp;
						&nbsp;

						<ToggleButtonGroup
							value={isDriveThru}
							onChange={(e, val) => setIsDriveThru(val)}
							exclusive 
							style={{'marginRight':'1em'}}>
							<ToggleButton value={true}>
								Drive Thru
							</ToggleButton>
							<ToggleButton value={false}>
								Lobby
							</ToggleButton>
						</ToggleButtonGroup>
						&nbsp;
						&nbsp;
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip>
									If you forget what the format is, download this
									file, it will give you the headers and the units
									you should use in each column
								</Tooltip>
							}>
							<Button
								variant='outline-secondary'
								onClick={downloadExample}>
								Download Example File
							</Button>
						</OverlayTrigger>
						<a
							href={downloadLink}
							ref={linkRef}
							download='example.csv' />
						
					</Row>
						<Row className='mt-3'>
							<Col className='d-flex justify-content-center'>
								{previewCSV()}
							</Col>
						</Row>
				</>
			)
		}
	}

	return (
		<div className='flex flex-col flex-1 justify-center items-center'>
			<div className='text-xl'>Data Uploader</div>
			{showUI()}
		</div>
	)

	return(
		<>
			<div className='d-flex justify-content-center'>
				<h2>Data Uploader</h2>
			</div>
			{showUI()}
		</>
	)
}