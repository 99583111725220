import { findTimeZone, getZonedTime } from 'timezone-support';
import dateFormat from "dateformat";

function parseIsoDatetime(dtstr) {
	var dt = dtstr.split(/[: T-]/).map(parseFloat);
	return new Date(
		dt[0],
		dt[1] - 1,
		dt[2],
		dt[3] || 0,
		dt[4] || 0,
		dt[5] || 0,
		0
	);
}

/**
 * Converts UTC date string to a locally correct js datetime object
 * @param {date string UTC (db style)} s 
 * @param {string} tz_string timezone
 */
const UTCStringToLocalDate = (s, tz_string) => {
    // Split
    let dt = s.split(/[: T-]/).map(parseFloat);

    let d = Date.UTC(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5]);
    let localString = (new Date(d)).toLocaleString('en-US', {timeZone:tz_string});

    // Now back to a datetimeobject that is tz wrong, but all else good
    let nd = new Date(localString);
    return nd;
}


export { parseIsoDatetime, UTCStringToLocalDate }