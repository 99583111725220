import React, { useState, useEffect } from 'react';
import MyNavBar from '../MyNavBar';
import LocationItem from './LocationItem';
import { useDataContext } from '../DataContext';
import FranchiseSelector from '../DownTracker/FranchiseSelector';
export default function Rebooter(props){
    
    const [franchiseData, setFranchiseData] = useState();
    const [selectedFranchise, setSelectedFranchise] = useState();
    const [locationData, setLocationData] = useState();
    const [filteredLocData, setFilteredLocData] = useState();

    const { routes } = useDataContext();

    /**
     * On load get the location/device data
     */
    useEffect(() => {
        // setLocationData(undefined);
        refreshData();
    }, [])

    const filterLocationByFranchise = (franchise) => {
        setSelectedFranchise(franchise);
        if (franchise.id == -1) {
            setFilteredLocData(locationData);
        } else {
            setFilteredLocData(locationData.filter((loc) => loc.franchise_id == franchise.id));
        }
    }

    const refreshData = (addOnFunc=(() => {})) => {
        let url = routes['getAllLocations'];
        fetch(url)
            .then(res => res.json())
            .then(data => {
                var resArr = [{name: 'All Franchises', id: -1}];
                data.forEach(function(item){
                    var i = resArr.findIndex(x => x.id == item.franchise_id);
                    if(i <= -1){
                        resArr.push(item.franchise);
                    }
                });
                resArr.sort((a, b) => (a.id > b.id) ? 1 : -1);

                setFranchiseData(resArr);
                setLocationData(data);

                if (selectedFranchise == undefined) {
                    setSelectedFranchise(resArr[0]);
                    setFilteredLocData(data);
                }

                addOnFunc();
            })
    }

    return (
        <>
            <MyNavBar />
            <div className='p-3 my-8'>
                {
                filteredLocData == undefined ? <>Loading Locations and Devices...</> : (
                    <>
                    <FranchiseSelector
                        allFranchises={franchiseData}
                        selectedFranchise={selectedFranchise}
                        setSelectedFranchise={filterLocationByFranchise}
                    />
                    <div className='w-full flex flex-wrap justify-center gap-8'>
                        {
                            filteredLocData.sort((a,b) => a.id - b.id).map(loc => <LocationItem data={loc} key={`locitem-${loc.id}`} refreshData={refreshData}/>)
                        }
                    </div>
                    </>
                    
                )}
            </div>
        </>
    )
}