// import firebase from 'firebase/app';
import firebase from 'firebase';
import "firebase/firestore";

var app;

try {
	var firebaseConfig = {
		apiKey: "AIzaSyBcAyHLJhat13yVwS2mLiDwWy-rpf98Agg",
		authDomain: "hellometer-f5fa4.firebaseapp.com",
		projectId: "hellometer",
		storageBucket: "hellometer.appspot.com",
		messagingSenderId: "579025009063",
		appId: "1:579025009063:web:a3702af17f74eae52c1e82",
		measurementId: "G-X6CRJ9Z34L"
	};
	// Initialize Firebase
	app = firebase.initializeApp(firebaseConfig);
} catch(err){
	if (!/already exists/.test(err.message)) {
		console.error('Firebase initialization error', err.stack)
	}
	app = firebase.app();
}

export const auth = app.auth();
export default app;
