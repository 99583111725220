/**
    ____        __        ______            __            __ 
   / __ \____ _/ /_____ _/ ____/___  ____  / /____  _  __/ /_
  / / / / __ `/ __/ __ `/ /   / __ \/ __ \/ __/ _ \| |/_/ __/
 / /_/ / /_/ / /_/ /_/ / /___/ /_/ / / / / /_/  __/>  </ /_  
/_____/\__,_/\__/\__,_/\____/\____/_/ /_/\__/\___/_/|_|\__/  
															 

 * 
 * Kenneth Lipke
 * This context is used not to help with local (client side)
 * cacheing and to standardized querry routes to it is easy 
 * to change between hitting a local endpoint and the cloud
 */

import React,  { useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

const DataContext = React.createContext();

// Needed for class components
export default DataContext;

export function useDataContext(){
	return useContext(DataContext);
}


/**
 * The "DataProvider" is just a convenient way to wrap other elemnts
 * and pass the context as props, the key here is that we pass "values" as 
 * the value prop to expose the context, and we still keep the "children"
 * note, this is a functional component
 * @param {Things} param0 
 */
export function DataProvider({ children }){
	const [cacheDict, updateCacheDict] = useState({});
	const { currentUser } = useAuth(); // so we can get current uid to check cache

	/**
	 * Helper to get ALL of the cache at once
	 */
	function getAllCacheData(){
		return cacheDict;
	}

	/**
	 * Helper to get how many minutes between two dates
	 * @param {Date} d1 
	 * @param {Date} d2 
	 */
	function minuteDiff(d1, d2){
		return Math.abs((d1.getTime() - d2.getTime())/60000);
	}

	/**
	 * Returns either the cache data for the given key or undefined
	 * will return the desired data only if:
	 *  + it exists (has been cached)
	 *  + the cache is still valid (i.e., given in last 5 minutes)
	 *  + was cached by the same user asking for it
	 * @param {String} which cacheKey
	 */
	function getCacheData(which){
		// Check if cache has entry
		if (!Object.keys(cacheDict).includes(which)){
			// Does not have it
			return undefined
		}

		// Has it, check if current user
		let dat = cacheDict[which];
		if (dat.uid !== currentUser.uid){
			return undefined;
		}

		// Check if in time zone
		if (minuteDiff(dat.ts, new Date()) > 5){
			return undefined
		}

		// Finally, looks good, send the data
		return cacheDict[which]['data'];
	}

	/**
	 * Function that sets cache data, 
	 * takes in a cacheKey and the data, 
	 * internally marks down the time at which the data came in
	 * as well as the user id for the person that sent it
	 * @param {String} which cacheKey
	 * @param {Anything} data data to cache
	 */
	function setCache(which, data){
		let date = new Date();
		let uid = currentUser.uid;
		let obj = {}
		let d= {
			'ts': date,
			'uid': uid,
			'data': data
		};
		cacheDict[which] = d
		updateCacheDict(cacheDict);
	}

	// Local host routes
	let local = "http://localhost:5000/";
	let remote = "https://api-iaily4lvlq-uc.a.run.app/";
	let remoteCloudRun = "https://api-iaily4lvlq-uc.a.run.app/";
	let remoteTest = "https://test-dot-hellometer.uc.r.appspot.com/"

	let localEmail = "http://localhost:5001/";
	let remoteEmail = "https://emails-test-dot-hellometer.uc.r.appspot.com/";

	let pre = remoteCloudRun;
	let preEmail = localEmail;

	
	const routes = {
		getAllUsers: `${pre}customer/getAllUsersFB`,
		getUserData: `${pre}customer/getUserData`,
		updateUser: `${pre}customer/updateUserAuthFB`,
		updateUserFirestore: `${pre}customer/updateUserFirestore`,
		locationData: `${pre}location/getLocations`,
		getAllLocations: `${pre}location/getAllLocations`,
		deleteUser: `${pre}customer/deleteUserFB`,
		reboot: `${pre}reboot_camera/reboot_by_id/`,
		createUser: `${pre}customer/createUserFB`,
		ping: `${pre}reboot_camera/ping_ssh/`,
		getDBData: `${pre}detection/get_full`,
		updateDetection: `${pre}detection/update`,
		deleteDetection: `${pre}detection/dropDetection`,
		updateRegion: `${pre}region/update`,
		addRegion: `${pre}region/post`,
		deleteRegion: `${pre}region/delete`,
		postDetectionAndRegion: `${pre}region/addDetectionAndRegion`,
		batchDeleteDetection: `${pre}detection/batchDrop`,

		buildCountEmail: `${preEmail}/build_count_email`,
		sendCountEmail: `${preEmail}/send_count_email`,
	};
	// web routes

	

	// Object to store the functions and objects
	// consumers of the context should expect
	const value = {
		getAllCacheData,
		getCacheData,
		setCache,
		routes,
	}

	return (
		<DataContext.Provider value={value}>
			{children}
		</DataContext.Provider>
	)
}








// const routes= {
	// 	locationPage: "http://localhost:5000/webapp/get_location_page_data_3_",
	// 	getLocationId: "http://localhost:5000/webapp/get_default_store_",
	// 	getStoreData: "http://localhost:5000/webapp/get_store_data_",
	// 	getKPIData: "http://localhost:5000/webapp/get_kpi_data_",
	// 	getCustomer: "http://localhost:5000/customer/get",
	// 	dayPart: "http://localhost:5000/webapp/get_day_part_data",
	// 	detectionData: 'http://localhost:5000/webapp/get_detection_data',
	// }
	// const routes= {
	// 	locationPage: `${pre}webapp/get_location_page_data_3_`,
	// 	getLocationId: `${pre}webapp/get_default_store_`,
	// 	getStoreData: `${pre}webapp/get_store_data_`,
	// 	getKPIData: `${pre}webapp/get_kpi_data_`,
	// 	getCustomer: `${pre}customer/get`,
	// 	dayPart: `${pre}webapp/get_day_part_data`,
	// 	detectionData: `${pre}webapp/get_detection_data`,
	// }