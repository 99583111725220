import React, { useState, useRef }  from 'react';
import BasicModal from '../utils/BasicModal';
import AddRegionForDetection from './AddRegionForDetection';
import {v4 as uid} from 'uuid';
import { getTimezoneOffset, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useDataContext } from '../DataContext';


export default function AddDetectionModal({setShow, locationData, refresh}){

	const { routes } = useDataContext();

	const [modelId, setModelId] = useState(-5);
	const [isDriveThru, setIsDriveThru] = useState(false);
	const [timeSpent, setTimeSpent] = useState(0);
	const [regionData, setRegionData] = useState([]);

	const dateTimeRef = useRef();


	/**
	 * 
	 * @param {uuid} uid
	 */
	const removeRegion = (uid) => {
		console.log("Drop", uid);
		let _new = [...regionData];
		_new = _new.filter((x) => x._id != uid);
		setRegionData(_new);
	}

	/**
	 * Helper function to update region data
	 * really helps to handle time stamps
	 * @param {unique region data _id} uid 
	 * @param {data to save it with} data 
	 */
	const updateRegionData = (uid, data) => {
		let _new = [...regionData];
		let ind = _new.findIndex(x => x._id == uid);

		_new[ind]['region'] = data.region;
		_new[ind]['time_spent'] = data.time_spent;
		let timeStamp = data.first_seen_datetime_utc;
		if (timeStamp !== ''){
			let d = zonedTimeToUtc(timeStamp, locationData.time_zone);
			timeStamp = d.toISOString();
		}
		_new[ind]['first_seen_datetime_utc'] = timeStamp;

		setRegionData(_new);
	}

	const postDetection = () => {
		// Make sure we have all fields
		let timeStamp = dateTimeRef.current.value;
		if (timeStamp == '' || timeSpent == undefined || modelId == undefined || isDriveThru == undefined){
			window.alert("Please fill in all fields first");
			return;
		}

		// Gather detection Data
		let d = zonedTimeToUtc(timeStamp, locationData.time_zone);
		timeStamp = d.toISOString();
		let detectionData = {
			model_id: modelId,
			is_drive_through: isDriveThru,
			time_spent: parseInt(timeSpent),
			first_seen_datetime_utc: timeStamp,
			day_pat: 1,
			location_id: locationData.id,
		}

		// Gather region data
		let rd = [...regionData];
		rd.forEach(d => {
			delete d._id;
		})

		// Make request
		console.log(detectionData);
		console.log(rd);
		let url = routes['postDetectionAndRegion'];
		let params = {
			detection: detectionData,
			regions: rd
		}
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			console.log(data);
			refresh();
		})
		.catch(err => {
			console.warn(err);
			window.alert("Error adding data");
		})

	}



	return (
		<BasicModal
			closeModal={() => {setShow(false)}}
			title={"Add Detection"}
			accept={postDetection}
			acceptText={"Add Detection"}>
			
			<div>
				Location ID will be {locationData.id}
				<div>
					<form>
						<label>
							Model Id
						</label>
						<input
							className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
							type='number' 
							value={modelId}
							onChange={(e) => setModelId(parseInt(e.target.value))}
							/>
					</form>
				</div>  
				<div>
					<form>
						<label>First Seen (local time)</label>
						<input 
							className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
							type="datetime-local" 
							ref={dateTimeRef}
							/>
					</form>
				</div>
				<div>
					<form>
						<label>
							Drive thru
						</label>
						<input
							className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
							type='checkbox' 
							checked={isDriveThru}
							onChange={() => setIsDriveThru(!isDriveThru)}
							/>
					</form>
				</div>  
				<div>
					<form>
							<label>Time Spent</label>
							<input 
								className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
								type="number" 
								value={timeSpent}
								onChange={(e) => {setTimeSpent(e.target.value)}}
								/>
					</form>
				</div>
			</div>

			<div className='w-full border-b text-lg'>
				Regions
			</div>

			{
				regionData.map((data) => {
					return (
						<AddRegionForDetection
							key={data._id}
							locationData={locationData}
							uid={data._id}
							removeRegion={removeRegion}
							updateData={updateRegionData}
							/>
					)
				})
			}

			<div>
				<button
					onClick={() => {
						// let _new = Object.assign([], regionData);
						let _new = [...regionData];
						console.log(uid());
						_new.push({_id: uid()})
						setRegionData(_new);
					}}
					className='btn bg-green-500 text-white hover:bg-green-700 px-2 py-1 rounded-lg'>
					Add Region
				</button>
			</div>

		</BasicModal>
	)
}