/**
   ______                 ____        __  _                 
  / ____/________  ____  / __ \____  / /_(_)___  ____  _____
 / /   / ___/ __ \/ __ \/ / / / __ \/ __/ / __ \/ __ \/ ___/
/ /___/ /  / /_/ / / / / /_/ / /_/ / /_/ / /_/ / / / (__  ) 
\____/_/   \____/_/ /_/\____/ .___/\__/_/\____/_/ /_/____/  
						   /_/                              
 * 
 * Ken Lipke
 * April 2021
 * 
 * Gets the options to set a "Cron job"
 * for sending out the emails later today
 * 
 * So, here is the plan:
 * (1) on load, check to see if there is anything there for the current date
 *     if so, show what it is
 * (2) have option to add cron job
 * 
 * Cron job consists of
 *    people it is going to
 * 
 * How do we want to store
 *  we store, url, and array of to's
 * 
 * Note, we need to start sending the summary to storage with a solid
 * naming convention, and have a way to send that in the backend
 * 
 * We will also need to add summary to the location drop down, and have 
 * a way to not edit any of it
 * 
 * 
 * example of a cron collection for a day document
 */

import React, { useState, useEffect } from 'react';
import app from '../firebase';
// import { Button, Row, Col } from 'react-bootstrap';
import AddressInput from './AddressInput';
import { KeyboardReturnOutlined } from '@material-ui/icons';

export default function CronOptions(props){

	const [cronData, setCronData] = useState();
	const [cronTo, setCronTo] = useState([]);
	const [reloadState, setReloadState] = useState();

	// On location change/load, get data
	useEffect(() => {
		getTodaysCron();
	}, [props.loc])

	// When location changes, be sure to clear t
	// useEffect(() => {
	// 	setCronTo([]);
	// }, [props.loc])


	/**
	 * Takes whatever is in to for the current location and updtes
	 * the DB cron with it
	 */
	const updateCron = () => {
		// Copy the cronData
		let newDat = Object.assign({}, cronData);

		// Get the URL For the email
		let url = props.getURL();

		// if this location change it
		newDat[props.loc] = {
			"url": url,
			"to": cronTo
		}

		// If no to's delete it
		if (cronTo.length == 0){
			delete newDat[props.loc];
		}

		console.log(newDat);
		// return;

		// Send to firebase
		const db = app.firestore();
		let d = getTodaysDate();
		db.collection('email_cron')
			.doc(d)
			.set(newDat)
			.then(r => {
				console.log("good");
				getTodaysCron();
				// setCronTo([]);
			})
			.catch(e => {console.warn(e)
				// setReloadState(Math.random())
			});

	}

	/**
	 * Clears ALL cron jobs for all locations
	 */
	const clearAll = () => {
		let v = window.confirm("This will clear ALL cron jobs for all locations, are you sure?");
		if (!v){
			return;
		}

		// Send to firebase
		const db = app.firestore();
		let d = getTodaysDate();
		db.collection('email_cron')
			.doc(d)
			.delete()
			.then(r => getTodaysCron())
			// .then(r => setCronTo([]));
	}


	const getTodaysCron = () => {
		// Clear anything while we wait
		setCronTo([]);

		// get today's date
		let d = getTodaysDate();

		// query the data
		// console.log("HAD TO UPDATE FIREBASE RULES---NEED NEED NEED AUTH HERE")
		const db = app.firestore();
		db.collection('email_cron')
			.doc(d)
			.get()
			.then(r => r.data())
			.then(d =>{
				setCronData(d);

				// If no entry for this location, do nothing yet
				if (d == undefined){return}

				// set the TO based on cron job
				if (Object.keys(d).includes(String(props.loc))){
					setCronTo(d[props.loc]['to'])
				}
			})
			.catch(e => console.warn(e));
	}


	const getTodaysDate = () => {
		let d = new Date();
		let year = d.getFullYear();
		let month = d.getMonth() + 1;
		month = month < 10 ? '0' + month : month;
		let day = d.getDate();
		day = day < 10 ? '0' + day : day;
		return `${month}-${day}-${year}`
	}

	return(
		<>
			<div className='mt-5 text-lg'>Send Later</div>
			<p>
				Will go out at Noon Pacific Time today (unless that has already happened,
				in which case this will do nothing)
			</p>
			<AddressInput 
				loc={props.loc}
				to={cronTo}
				setTo={setCronTo}/>
			<div className='mt-4 grid grid-cols-3 gap-4'>
				<div>
					<button variant='outline-warning'
						className=' w-full px-2 py-1 border-2 rounded-lg border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-white'
						onClick={updateCron}>
						Update Cron Job
					</button>
				</div>
				<div>
					<button variant='outline-success'
						className='w-full px-2 py-1 border-2 rounded-lg border-green-500 text-green-500 hover:bg-green-500 hover:text-white'
						onClick={getTodaysCron}>
						Refresh
					</button>
				</div>
				<div>
					<button variant='outline-danger'
						className='w-full px-2 py-1 border-2 rounded-lg border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
						onClick={clearAll}>
						Clear ALL Cron Jobs
					</button>
				</div>
			</div>
		</>
	)
}