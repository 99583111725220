import React, { useState } from 'react';
import { defaultAddresess } from "../locationData.js";
import AddressInput from './AddressInput';


export default function SendModule({emailId, endPoint, parameters}){

	const [sending, setSending] = useState(false);
	const [to, setTo] = useState([]);

	const send = () => {
		setSending(true);

		// attach to's to parameters
		let sendParams = Object.assign({}, parameters);
		sendParams['to'] = to;

		fetch(endPoint, {
			method: 'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(sendParams)
		})
		.then(res => {
			setSending(false);
			window.alert("Sent!");
		})
		.catch(err => {
			setSending(false);
			window.alert("Had an issue sending");
		})
	}

	return (
		<div className='text-left p-4 border border-gray-200 rounded shadow-lg max-w-xl'>
			<h2 className='text-bold text-xl'>
				Send Now
			</h2>

			<AddressInput 
				loc={emailId}
				to={to}
				setTo={setTo}
				/>

			<div>
				<button
					onClick={send}
					disabled={sending}
					className={`btn p-2 w-full bg-green-500
						text-white hover:bg-green-700 rounded-lg 
						${sending ? 'cursor-wait bg-green-900': ''}`}
					>
					Send
				</button>
			</div>
		</div>
	)
}