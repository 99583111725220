import React, { useState, useEffect } from 'react';
import MyNavBar from '../MyNavBar';
import LocationItem from './LocationItem';
import { useDataContext } from '../DataContext';
import app from '../firebase';
import FranchiseSelector from './FranchiseSelector';
export default function DownTracker(props){

    const [franchiseData, setFranchiseData] = useState();
    const [selectedFranchise, setSelectedFranchise] = useState();
    const [locationData, setLocationData] = useState();
    const [filteredLocData, setFilteredLocData] = useState();
    const [downTrackerData, setdownTrackerData] = useState();
    const [checked, setChecked] = React.useState(false);

    const { routes } = useDataContext();

    /**
     * On load get the location/device data
     */
    useEffect(() => {
        refreshData();
    }, [])

    const filterLocationByFranchise = (franchise) => {
        setSelectedFranchise(franchise);
        if (franchise.id == -1) {
            setFilteredLocData(locationData);
        } else {
            setFilteredLocData(locationData.filter((loc) => loc.franchise_id == franchise.id));
        }
    }

    const filterLocationByOpenVPN = (value) => {
        setChecked(value);

        if (value) {
            setFilteredLocData(locationData.filter((loc) => 'status' in loc));
        } else {
            setFilteredLocData(locationData);
        }
    }

    const refreshData = (addOnFunc=(() => {})) => {
        let url = routes['getAllLocations'];
        fetch(url)
            .then(res => res.json())
            .then(data => {
                if (props.trackerPath == "down_tracker_europe") {
                    data = data.filter((location => [9, 28, 30, 33].includes(location.franchise_id)))
                }
                return data
            })
            .then(data => {
                var resArr = [{name: 'All Franchises', id: -1}];
                data.forEach(function(item){
                    var i = resArr.findIndex(x => x.id == item.franchise_id);
                    if(i <= -1){
                        resArr.push(item.franchise);
                    }
                });
                resArr.sort((a, b) => (a.id > b.id) ? 1 : -1);

                setFranchiseData(resArr);
                setLocationData(data);

                if (selectedFranchise == undefined) {
                    setSelectedFranchise(resArr[0]);
                    setFilteredLocData(data);
                }

                // fetch('https://o0avnmoiuj.execute-api.us-west-2.amazonaws.com/Prod/internal/openVpnStatus')
                //     .then(res => res.json())
                //     .then(openVpnData => {
        
                //         openVpnData.map(item => {
                //             const index = data.findIndex(loc => { 
                //                 return item.name.split('L')[1] == loc.id;
                //             });
        
                //             if (index != -1) {
                //                 data[index]['status'] = item.connectionStatus;
                //             }
                //         });

                        

                //     })

                addOnFunc();
            })

        const db = app.firestore();
        db.collection('internal')
            .doc(props.trackerPath)
            .get()
            .then(r => {
                let dat = r.data();
                setdownTrackerData(dat['devices']);
            })
            .catch(e => {
                console.warn(e);
            });
    }

    return (
        <>
            <MyNavBar />
            <div className='p-3 my-8'>
                {
                    (filteredLocData == undefined || downTrackerData == undefined) ? <>Loading Locations and Devices...</> : (<>
                    <div className='flex justify-between mx-8'>
                        <FranchiseSelector
                            allFranchises={franchiseData}
                            selectedFranchise={selectedFranchise}
                            setSelectedFranchise={filterLocationByFranchise}
                        />
                        <div>
                            <input className='mr-2'
                                type="checkbox" 
                                checked={checked}
                                onChange={ () => filterLocationByOpenVPN(!checked)}
                            />
                            Show only OpenVPN Locations
                        </div>
                    </div>
                    <div className='w-full flex flex-wrap justify-center gap-8'>
                        {
                            filteredLocData.sort((a,b) => a.id - b.id).map(loc => <LocationItem downTrackerData={downTrackerData} data={loc} key={`locitem-${loc.id}`} refreshData={refreshData} trackerPath={props.trackerPath}/>)
                        }
                    </div>
                </>)}
            </div>
        </>
    )
}