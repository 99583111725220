import React, { useState } from "react";
import { useDataContext } from "../DataContext";
import app from "../firebase";
import firebase from 'firebase';
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

export default function DeviceRow({ entireDownTracker, inDownTracker, data, refreshData, trackerPath }) {

	const add = () => {
		const today = new Date()
		const yesterday = new Date(today)

		yesterday.setDate(yesterday.getDate() - 1)

		const db = app.firestore();
        db.collection('internal')
            .doc(trackerPath)
            .update({
				devices: firebase.firestore.FieldValue.arrayUnion(
					{
						id: data.id,
						location_id: data.location_id,
						new_bucket: true,
						retry_connect: 3,
						snooze_until: firebase.firestore.Timestamp.fromMillis(yesterday),
						cameras: data.cameras.map(cam => cam.storage_id),
						detect_frequency: 1,
						video_frequency_min: 10
					}
				)
			})
			.then(r => {
				refreshData();
			})
            .catch(e => {
                console.warn(e);
            });
	}

	const remove = () => {
		const db = app.firestore();
        db.collection('internal')
            .doc(trackerPath)
            .update({
				devices: firebase.firestore.FieldValue.arrayRemove(
					inDownTracker
				)
			})
			.then(r => {
				refreshData();
			})
            .catch(e => {
                console.warn(e);
            });
	}

	const editDevice = (key, value) => {
		let objIndex = entireDownTracker.findIndex((obj => obj.id == data.id));
		entireDownTracker[objIndex][key] = value;

		const db = app.firestore();
        db.collection('internal')
            .doc(trackerPath)
            .update({
				devices: entireDownTracker
			})
			.then(r => {
				refreshData();
			})
            .catch(e => {
                console.warn(e);
            });
	}


	return (
		<tr key={`dev-${data.id}`} className='even:bg-gray-100'>
			<td className='border border-gray-200'>{data.id}</td>	
			{ inDownTracker != undefined ? 
				<td className='border border-gray-200'>
					<button
						onClick={() => editDevice('retry_connect', inDownTracker.retry_connect > 1 ? inDownTracker.retry_connect - 1 : 1)}
						className="btn border p-1 rounded-lg text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
						-
					</button>
					
					{" " + inDownTracker.retry_connect} {inDownTracker.retry_connect > 1 ? 'times' + " " : 'time' + " "}
				
					<button
						onClick={() => editDevice('retry_connect', inDownTracker.retry_connect + 1)}
						className="btn border p-1 rounded-lg text-xs border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
						+
					</button>

				</td>
				
			: 
				<td className='border border-gray-200'>{'-'}</td>
			}		
			{ inDownTracker != undefined ? 
				<td className='border border-gray-200'>
					<button
						onClick={() => editDevice('detect_frequency', inDownTracker.detect_frequency > 1 ? inDownTracker.detect_frequency - 1 : 1)}
						className="btn border p-1 rounded-lg text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
						-
					</button>

					{" " + inDownTracker.detect_frequency} {inDownTracker.detect_frequency > 1 ? 'hours' + " " : 'hour' + " "}
				
					<button
						onClick={() => editDevice('detect_frequency', inDownTracker.detect_frequency + 1)}
						className="btn border p-1 rounded-lg text-xs border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
						+
					</button>

				</td>
				
			: 
				<td className='border border-gray-200'>{'-'}</td>
			}
			{ inDownTracker != undefined ? 
				<td className='border border-gray-200'>

					<button
						onClick={() => editDevice('video_frequency_min', inDownTracker.video_frequency_min > 10 ? inDownTracker.video_frequency_min - 10 : 10)}
						className="btn border p-1 rounded-lg text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
						-
					</button>

					{" " + inDownTracker.video_frequency_min} {inDownTracker.video_frequency_min > 1 ? 'mins' + " " : 'min' + " "}
					
					<button
						onClick={() => editDevice('video_frequency_min', inDownTracker.video_frequency_min + 10)}
						className="btn border p-1 rounded-lg text-xs border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
						+
					</button>


				</td>
				
			: 
				<td className='border border-gray-200'>{'-'}</td>
			}
			{ inDownTracker != undefined ? 
				<td className='border border-gray-200'>
					<input type="checkbox" checked={inDownTracker.new_bucket} onChange={(e) => editDevice('new_bucket', !inDownTracker.new_bucket)} />
				</td>
				
			: 
				<td className='border border-gray-200'>{'-'}</td>
			}
			{ inDownTracker != undefined ? 
				<td className='border border-gray-200'>
					<ReactDatePicker selected={inDownTracker.snooze_until.toDate()} onChange={(date) => editDevice('snooze_until', firebase.firestore.Timestamp.fromMillis(date))} />
				</td>
				
			: 
				<td className='border border-gray-200'>{'-'}</td>
			}
			<td className='border border-gray-200'>
				{ inDownTracker == undefined ?  
					<button
						onClick={() => add()}
						className="btn border p-1 rounded-lg text-xs border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
						Add
					</button>
					: 
					<button
						onClick={() => remove()}
						className="btn border p-1 rounded-lg text-xs border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
						Remove
					</button>
				}
			</td>
		</tr>
	);
}
