import React from "react";

export default function FranchiseSelector({
	allFranchises,
	selectedFranchise,
	setSelectedFranchise,
}) {

    /**
     * Updates the selected franation by id
     * @param {int} id Franchise id
     */
    const updateFranchise = (id) => {
        let fran = allFranchises.filter(x => x.id == id)[0];
        setSelectedFranchise(fran);
    }

	return (allFranchises === undefined || selectedFranchise === undefined) ? <>Loading...</> : (
		<form>
			<select
                value={selectedFranchise.id}
                onChange={(e) => updateFranchise(e.target.value)}
                className='border border-gray-300 shoadow-sm rounded-lg px-2 py-1'
                >
                {
                    allFranchises.map(fran => {
                        return (
                            <option value={fran.id} key={fran.id}>
                                {fran.name}  (id: {fran.id})
                            </option>
                        )
                    })
                }
            </select>
		</form>
	);
}
