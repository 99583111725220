/**
    __                _     
   / /   ____  ____ _(_)___ 
  / /   / __ \/ __ `/ / __ \
 / /___/ /_/ / /_/ / / / / /
/_____/\____/\__, /_/_/ /_/ 
            /____/          
 * Kenneth Lipke
 * Login component gathers and checks creditnails with auth helpers
 */


import React, { useRef, useState, useEffect} from 'react';
import {Container, Form, Alert} from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { Link, useHistory } from 'react-router-dom';
// import logo from "../../assets/logo_circle.png";

export default function Login(){

	const emailRef = useRef();
	const passwordRef = useRef();
	const { login } = useAuth();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false); // used to prevent clicking signup
	// mutiple times and creating multiple accounts
	const history = useHistory();

	/**
	 * Handles submit from login form
	 * passes given credientials to firebase helpers in AuthContext
	 * and verifies they are correct
	 * @param {Event} e 
	 */
	async function handleSubmit(e){
		e.preventDefault(); // prevent form from refreshing

		try{
			setError('');
			setLoading(true);
			await login(emailRef.current.value, passwordRef.current.value);

			// made it here, so login succesfful 
			setLoading(false);
			history.push("/");
		} catch {
			setError("Failed to login, check your credentials and try again");
			setLoading(false);
		}

		// setLoading(false);
	}

	useEffect(() => {
		console.log("Mounting login");
	}, [])

	return(
		<div className='w-full h-screen flex justify-center items-center bg-blue-500'>
			<div className='w-full max-w-sm p-8 border-4 border-white rounded-lg bg-white shadow-2xl'>
				<div className='text-xl text-center mb-4'>
					Hellometer Admin
				</div>
				<div className='flex flex-col gap-4'>
					{
						error && (
							<div className='p-2 text-red-600 bg-red-200 border-2 border-red-300 w-full rounded-lg text-center'>
								{error}
							</div>
						)
					}
					<form className='flex flex-col gap-4'
						onSubmit={handleSubmit}>
						<div>
							<input
								type='text'
								placeholder='Email'
								ref={emailRef}
								className='p-2 border-2 border-gray-300 rounded-lg w-full'
								/>
						</div>
						<div>
							<input
								type='password'
								placeholder='Password'
								ref={passwordRef}
								className='p-2 border-2 border-gray-300 rounded-lg w-full'
								/>
						</div>
						<div>
							<button
								disabled={loading}
								type='submit'
								className='btn rounded-lg w-full px-2 py-1 border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'>
								Login
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);



	return(
		<React.Fragment>
			<Container className='d-flex align-items-center justify-content-center'
				style={{minHeight:'100vh'}}>
				<div className='w-100' style={{maxWidth:'400px'}}>
					{/* <Card>
						<Card.Body> */}
					<div className='border-widget'>
							{/* <div className='w-100' style={{textAlign:'center'}}>
								<img src={logo} className='center-block' style={{width:"35%"}} alt=""/>
							</div> */}
							<h2 className='headerText text-center mb-3'>
								Login
							</h2>
							{error && <Alert variant='danger'>{error}</Alert>}
							<Form onSubmit={handleSubmit}>
								<Form.Group id='email' >
									<Form.Control type='email' placeholder='email' ref={emailRef} data-testid="email" required />
								</Form.Group>
								<Form.Group id='password' >
									<Form.Control type='password' placeholder='password' ref={passwordRef} data-testid='password' required />
								</Form.Group>
								<button disabled={loading} className='hellometer-outline-button w-100' type='submit' data-testid='submit'>
									Login
								</button>
							</Form>
					</div>
					{/* <div className='w-100 text-center mt-2'>
						Need an account? <Link to='/signup'>Signup</Link>
						<br></br>
						<Link to='forgot-password'>Reset Password</Link>
					</div> */}
				</div>
			</Container>
			
		</React.Fragment>
	);
}
