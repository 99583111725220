import React, { useState, useEffect } from 'react';
import { useDataContext } from '../DataContext';
import Actions from './Actions';
import ViewEditCell from './ViewEditCell';
import RegionRow from './RegionRow';
import BasicModal from '../utils/BasicModal';

export default function DetectionRow({i, detectionData, regionData, locationData, refresh, setRegionAddId, deleteMode, toDelete, setToDelete}){

	const { routes } = useDataContext();

	const [expanded, setExpanded] = useState(false);
	const [editMode, setEditMode] = useState(false);// in edit mode, show different actions: save, cancel
	const [newData, setNewData] = useState({});
	const [showRegionModal, setShowRegionModal] = useState(false);

	useEffect(() => {
		// Whenever edit mode changes, clear the newData
		setNewData({});
	}, [editMode])


	// I think we will have to move tr into this component
	// then we can dynamically show a SECOND (or many) tr
	// for the region data

	const deleteDetection = () => {
		let r = window.confirm("Are you sure you want to delete? (it may take a moment)");
		if (!r){return}

		// Now just change model id to -7
		let url = routes['updateDetection'];
		let params = {
			id: detectionData.id,
			model_id: -7
		}
		setEditMode(false);
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			console.log(data);
			refresh();
		})
		.catch(err => console.warn(err));

		// OLD when we actually deleted
		// let url = routes['deleteDetection'];
		// let params = {id: detectionData.id};
		// fetch(url, {
		// 	method: 'POST',
		// 	headers: {'Content-Type': 'application/json'},
		// 	body: JSON.stringify(params)
		// })
		// 	.then(res => {
		// 		refresh();
		// 	})
		// 	.catch(err => console.warn(err));
	}

	/**
	 * Makes DB call to save the changes to the given detection
	 */
	const save = () => {
		// If nothing new, leave it
		if (Object.keys(newData).length === 0){setEditMode(false); return;}

		let url = routes['updateDetection'];
		let params = Object.assign({}, newData);
		params['id'] = detectionData.id;
		setEditMode(false);
		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			console.log(data);
			refresh();
		})
		.catch(err => console.warn(err));
	}

	const updateField = (fieldId, newValue) => {
		console.log("Update", fieldId, "with", newValue);
		let _new = Object.assign({}, newData);
		_new[fieldId] = newValue;
		setNewData(_new);
		console.log(_new);
	}

	return (
		<>
		<tr
			className={`${i % 2 === 1 ? 'bg-gray-200': ''} hover:bg-blue-300
				${detectionData.model_id == -7 ? ( i%2==0 ? 'bg-red-100' : 'bg-red-200') : ''}
				${toDelete.includes(detectionData.id) ? 'bg-red-300' : ''}`} >
			<td>{detectionData.id}</td>
			<td>
				<ViewEditCell
					fieldId={"model_id"}
					value={detectionData.model_id}
					type={"int"}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
				/>
			</td>
			<td>
				<ViewEditCell
					fieldId={"first_seen_datetime_utc"}
					value={detectionData.first_seen_datetime_utc}
					type={"datetime"}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
				/>
			</td>
			<td>
				{/* {data.is_drive_through ? <>&#128663;</> : <>&#128694;</>} */}
				<ViewEditCell
					fieldId={"is_drive_through"}
					value={detectionData.is_drive_through}
					type={"bool"}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
				/>
			</td>
			<td>
				{detectionData.location_id}
			</td>
			<td>
				<ViewEditCell
					fieldId={"time_spent"}
					value={detectionData.time_spent}
					type={"int"}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
				/>
			</td>
			<td>
				<ViewEditCell
					fieldId={"valid_time_spent"}
					value={detectionData.valid_time_spent}
					type={"int"}
					updateField={updateField}
					editMode={editMode}
					locationData={locationData}
				/>
			</td>
			<td className="inline-flex float-right">
				{
					deleteMode ? (
						<input
							type='checkbox'
							checked={toDelete.includes(detectionData.id)}
							onChange={() => {
								if (!toDelete.includes(detectionData.id)){
									// add it
									let _new = [...toDelete];
									_new.push(detectionData.id);
									setToDelete(_new);
								}
								else{
									// remove it
									let _new = [...toDelete];
									_new = _new.filter(x => x != detectionData.id);
									setToDelete(_new);
								}
							}}
							/>
					) : 
					<Actions
						editMode={editMode}
						setEditMode={setEditMode}
						deleteDetection={deleteDetection}
						expanded={expanded}
						setExpanded={setExpanded}
						save={save}
					/>
				}
			</td>
		</tr>
		{
			expanded ? (
				<tr className={`${i % 2 === 1 ? 'bg-gray-200': ''}`}>
					<td colSpan={7}>
						<div className='mx-4 p-2 my-2 border border-gray-600 rounded-lg'>
							<table className='w-full'>
								<thead className='text-left'>
									<tr>
										<th>id</th>
										<th>First Seen</th>
										<th>Region</th>
										<th>Time Spent</th>
										<th className='text-right'>Actions</th>
									</tr>
								</thead>
								<tbody>
									{
										regionData.map((reg, i) => {
											return(
												<RegionRow
													key={reg.id}
													regionData={reg}
													locationData={locationData}
													refresh={refresh}
													i={i}
													/>
											)
										})
									}
								</tbody>
							</table>
							<div className='w-full mt-2 text-center'>
								<button 
									className='btn bg-green-500 text-white hover:bg-green-700 px-2 py-1 rounded-lg text-sm'
									onClick={() => {setRegionAddId(detectionData.id)}}>
									Add Region
								</button>
							</div>
						</div>
					</td>
				</tr>
			) : null
		}
		</>
	);
}