import React, { useState } from 'react';


export default function TimeSpentFilter({filterRange, setFilterRange, isFiltered, setIsFiltered}){

	const [innerRange, setInnerRange] = useState([30, 2000]);

	const updateRangeHelper = (which, val) => {
		let _new = [...filterRange];
		if (which === 'max'){
			_new[1] = parseInt(val);
		}
		else{
			_new[0] = parseInt(val);
		}
		// setFilterRange(_new);
		setInnerRange(_new);
	}

	const applyRange = () => {
		setFilterRange(innerRange);
	}

	return(
		<div>
			<div>
				<form>
					<label>
						Filter Data
					</label>
					<input 
						className='ml-2'
						type='checkbox'
						checked={isFiltered}
						onChange={() => setIsFiltered(!isFiltered)}
						/>
				</form>
			</div>
			{
				isFiltered ? (
					<>
						<form
							id='filterForm'
							onSubmit={(e) => {applyRange(); e.preventDefault();}}>
							<label>
								Min Time Spent
							</label>
							<input
								className='border-2 my-1 px-2 py-1 rounded-lg mx-2'
								type='number'
								value={innerRange[0]}
								onChange={(e) => updateRangeHelper('min', e.target.value)}
								/>

							<label>
								Max Time Spent
							</label>
							<input
								className='border-2 my-1 px-2 py-1 rounded-lg ml-2'
								type='number'
								value={innerRange[1]}
								onChange={(e) => updateRangeHelper('max', e.target.value)}
								/>
						</form>
						<button
							type='submit'
							form='filterForm'
							onClick={applyRange}
							className='btn border border-indigo-500 text-indigo-500 hover:bg-indigo-500 hover:text-white rounded-lg px-2 py-1 text-xs'>
							Apply
						</button>

					</>
				) : null
			}
		</div>
	)
}