import React, { useEffect, useState } from 'react';


export default function CustomerList({customerList, setSelectedCustomer, getCustomerList}){

	const [filteredCustomerList, setFilteredCustomerList] = useState(customerList);

	useEffect(() => {
		setFilteredCustomerList(customerList);
	}, [customerList])

	/**
	 * filters the customer list to match emails and uid that have overlap
	 * with the given key
	 * @param {string} key 
	 */
	const filterList = (key) => {
		let newList = customerList.filter(x => {
			return (x.email.toLocaleLowerCase().includes(key) 
									|| x.uid.toLocaleLowerCase().includes(key))
		});
		setFilteredCustomerList(newList);
	}

	const tableRowClick = (uid) => {
		console.log("Clicked", uid);
		setSelectedCustomer(uid);
	}

	const renderDate = (d) => {
		d = new Date(d);
		if (d.getFullYear() < 2000){
			return <span className='text-gray-400 italic'>Never</span>
		}
		// return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
		return d.toLocaleString()
	}

	/**
	 * Helper to only render the first bit of the Id so it
	 * doesn't take up too much space
	 * @param {string} id 
	 */
	const renderId = (id) => {
		return `${id.substr(0, 10)}...`
	}

	const renderCustomerList = () => {
		if (filteredCustomerList == undefined){
			return <>Loading Customers... </>
		}
		return (
			<table className='w-full'>
				<thead>
					<tr className='bg-gray-100 text-gray-500 text-left'>
						<th className='py-2'>Email</th>
						<th>UID</th>
						<th>Created</th>
						{/* <th>Last Sign-in</th> */}
						<th>Last Refresh</th>
					</tr>
				</thead>
				<tbody>
				{
					filteredCustomerList.map(customer => {
						return (
							<tr key={`customerList--${customer.uid}`}
								className='even:bg-gray-50 hover:bg-gray-200 cursor-pointer'
								onClick={() => tableRowClick(customer.uid)}>
								<td className='py-1 pl-1'>{customer.email}</td>
								<td className='px-1'>{renderId(customer.uid)}</td>
								<td className='px-1'>{renderDate(customer.creation_timestamp)}</td>
								{/* <td className='px-1'>{renderDate(customer.last_sign_in_timestamp)}</td> */}
								<td className='px-1'>{renderDate(customer.last_refresh_timestamp)}</td>
							</tr>
						)
					})
				}
				</tbody>
			</table>
		)
	}

	return(
		<>
			<div>
				<div className='flex justify-center'>
					<input
						type='text'
						placeholder='Search for email or user id'
						className='px-1 py-2 border-2 border-gray-200 rounded-lg w-80'
						onChange={(e) => filterList(e.target.value)}
						/>
					<button
						onClick={getCustomerList}
						className='btn border-2 ml-2 rounded-lg px-2 border-gray-400 text-gray-400 hover:bg-gray-400 hover:text-white'>
						Refresh
					</button>
				</div>
			</div>
			
			<div className='mt-4 mx-3' style={{'maxHeight': '70vh', 'overflow':'auto'}}>
				{renderCustomerList()}
			</div>
		</>
	)
}