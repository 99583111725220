/**
   _____                ______        __  _                 
  / ___/___  ____  ____/ / __ \____  / /_(_)___  ____  _____
  \__ \/ _ \/ __ \/ __  / / / / __ \/ __/ / __ \/ __ \/ ___/
 ___/ /  __/ / / / /_/ / /_/ / /_/ / /_/ / /_/ / / / (__  ) 
/____/\___/_/ /_/\__,_/\____/ .___/\__/_/\____/_/ /_/____/  
						   /_/                              
 * Ken Lipke
 * April 2021
 * 
 * Options for sending the edited email
 */

import React, { useState } from "react";
// import { Row, Col, Button, Spinner } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

import { defaultAddresess } from "../locationData.js";
import AddressInput from './AddressInput';

export default function SendOptions(props) {

	const showSpinner = () => {
		if (!props.canSend){
			return <CircularProgress size='1rem' thickeness={5}/>
		}
		// if (!props.canSend) {
		// 	return <Spinner as="span" animation="border" size="sm" role="status" />;
		// }
	};

	return (
		<div className='mt-5'>
			<div className='text-lg'>Send Now</div>
			<AddressInput 
				loc={props.loc}
				to={props.to}
				setTo={props.setTo} />
			<div className='flex justify-center mt-4'>
				<button
					disabled={!props.canSend || !props.canBuild}
					onClick={props.send} 
					className='btn p-2 w-1/2 border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white rounded-lg'>
					{showSpinner()} &nbsp; Send
				</button>
			</div>
		</div>
	)

	// return (
	// 	<>
	// 		<h3 className='mt-5'>Send Now</h3>
			// <AddressInput 
			// 	loc={props.loc}
			// 	to={props.to}
			// 	setTo={props.setTo} />
	// 		<Row className="mt-4">
	// 			<Col class="d-flex justify-content-center w-100">
	// 				<Button
	// 					variant="success"
	// 					className="w-50"
	// 					disabled={!props.canSend || !props.canBuild}
	// 					onClick={props.send}>
	// 					{showSpinner()} &nbsp; Send
	// 				</Button>
	// 			</Col>
	// 		</Row>
	// 	</>
	// );
}
