/**
    ___         __  __    ______            __            __ 
   /   | __  __/ /_/ /_  / ____/___  ____  / /____  _  __/ /_
  / /| |/ / / / __/ __ \/ /   / __ \/ __ \/ __/ _ \| |/_/ __/
 / ___ / /_/ / /_/ / / / /___/ /_/ / / / / /_/  __/>  </ /_  
/_/  |_\__,_/\__/_/ /_/\____/\____/_/ /_/\__/\___/_/|_|\__/  
                                                             
 * 
 * Kenneth Lipke
 * Context for Authorization, relies heavily on a great youtube tutorial: 
 * 	https://www.youtube.com/watch?v=PKwu15ldZ7k
 * This holds all the helper functions to login, logout, signup
 * change password, change email, etc. 
 * Everywhere, except the white list, it leans totally on firebase sdk functions
 */


import React,  { useContext, useState, useEffect } from 'react';
import { auth } from './firebase';


const AuthContext = React.createContext();

// Needed for class components
export default AuthContext;

export function useAuth(){
	return useContext(AuthContext);
}

export function AuthProvider({ children }){
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(true);

	function signup(email, password){
		return auth.createUserWithEmailAndPassword(email, password)
	}

	function login(email, password){
		return auth.signInWithEmailAndPassword(email, password);
	}

	function logout(){
		return auth.signOut();
	}

	function resetPassword(email){
		return auth.sendPasswordResetEmail(email);
	}

	function updateEmail(email){
		return currentUser.updateEmail(email);
	}

	function updatePassword(password){
		return currentUser.updatePassword(password);
	}

	function checkWhiteList(email){
		let whitelistUrl = 'https://api-iaily4lvlq-uc.a.run.app/whitelist/check_email_'+email;
		let out = fetch(
            whitelistUrl, 
                {
                    method:'GET'
                }
            )
			.then(r => {
				return r.json();
			}).then(json => {
                //do something
            })
			.catch((e) => {
				console.warn(e);
				return false;
			});
		return out;
	}

	useEffect( () => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setCurrentUser(user);
			await setLoading(false); // have user done loading
		});

		return unsubscribe;
	}, [])

	const value = {
		currentUser,
		signup,
		login,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		checkWhiteList,
	}

	// Only if not loading do we want to render the chidlren
	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}