import React from 'react';
import MyNavBar from './MyNavBar';

export default function LinksPage(props){

	return(
		<>
			<MyNavBar />
			<div className='px-8 w-full max-w-xl mx-auto my-8'>
				<div className=''>
					<h2 className='text-xl font-bold'>
						Useful Links
					</h2>
					<ul className='list-disc'>
						<li>
							<a href="https://api-iaily4lvlq-uc.a.run.app/admin/"
								target="_blank" rel="noopener noreferrer"
								className='underline text-blue-500'>
								Admin Portal
							</a>
						</li>
						<li>
							<a href="https://api-iaily4lvlq-uc.a.run.app/location/are_down_v2"
								target="_blank" rel="noopener noreferrer"
								className='underline text-blue-500'>
								Down Tracker
							</a>
						</li>
						<li>
							<a href='https://app.hellometer.io'
								target="_blank" rel="noopener noreferrer"
								className='underline text-blue-500'>
								Web App
							</a>
						</li>
						<li>
							<a href='https://live.hellometer.io'
								target="_blank" rel="noopener noreferrer"
								className='underline text-blue-500'>
								Live Video VM
							</a>
						</li>

					</ul>
				</div>
			</div>
		</>
	)
}