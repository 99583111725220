/**
 * Note, the sourcemap wasn't sending in dev mode for me for some reason,
 * so I went in node_modules/react-scripts/config/webpack.config.js
 * and searched for "devtool" and hard-coded it to 'sourcemap'
 * this is in line 180
 * 
 * Likely this will always be okay.... so just something to note
 */


// import "bootstrap/dist/css/bootstrap.min.css";

import PrivateRoute from './PrivateRoute';
import { AuthProvider } from "./AuthContext";
import { DataProvider } from './DataContext';
import Login from './Login';
import "./App.css";

import {BrowserRouter as Router, Route } from 'react-router-dom';
// import EmailSender from './EmailSender';
import EmailEditor from './Emails/EmailEditor';
import CustomerAdminPage from './CustomerAdmin/CustomerAdminPage';
import LinksPage from './LinksPage';
import Help from './Help';
import Rebooter from './Rebooter/Rebooter';
import EmailPage from './Emails2/EmailPage';
import DataEditor from './DataEditor/DataEditor';
import DownTracker from './DownTracker/DownTracker';
import Deployer from './Deployer/Deployer';

function App() {
	return (
		<Router>
			<AuthProvider>
				<DataProvider>
					<PrivateRoute exact path="/" component={CustomerAdminPage} />
					<PrivateRoute exact path="/emaileditor" component={EmailEditor} />
					<PrivateRoute path="/emails" component={EmailPage} />
					<PrivateRoute path="/customer" component={CustomerAdminPage} />
					<PrivateRoute path="/data" component={DataEditor} />
					{/* <PrivateRoute path='/help' component={Help} /> */}
					<PrivateRoute path="/downtracker" component={DownTracker} trackerPath="down_tracker" />
					<PrivateRoute path="/eudowntracker" component={DownTracker} trackerPath="down_tracker_europe" />
					<PrivateRoute path="/rebooter" component={Rebooter} />
					<PrivateRoute path="/deployer" component={Deployer} />
					<PrivateRoute path="/links" component={LinksPage} />
					<Route path="/login" component={Login} />
				</DataProvider>
			</AuthProvider>
		</Router>
	);
}

export default App;
