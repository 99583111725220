import React, { useState, useEffect } from "react";
import { findTimeZone, getZonedTime } from 'timezone-support';
import { parseIsoDatetime, UTCStringToLocalDate } from "./helpers";
import dateFormat from "dateformat";
import { getTimezoneOffset, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export default function ViewEditCell({
	fieldId,
	value,
	type,
	updateField,
	editMode,
	locationData
}) {
	
	const [editValue, setEditValue] = useState();

	// Any time the passed value changes (new data), update edit value
	// so we are storing the most recent
	useEffect(() => {
		setEditValue(value);
	}, [value, editMode])


	/**
	 * Helper to convert the given datestring to utc timestamp with 
	 * appropriate timezone corrections
	 * @param {date string (no timezone)} ds 
	 */
	const dateUpdateHelper = (ds) => {
		console.log("Initial Value", value);
		console.log("New stamp", ds);
		let d = zonedTimeToUtc(ds, locationData.time_zone);
		let newVal = d.toISOString();
		setEditValue(newVal);
		updateField(fieldId, newVal);
	}
	
	/**
	 * Renders the display value with any flourishes from type
	 */
	const renderDisplay = () => {
		if (type === 'bool'){
			return value ? <>&#128663;</> : <>&#128694;</>;
		}
		else if (type === 'datetime'){
			let d = UTCStringToLocalDate(value, locationData.time_zone);
			let formatted = dateFormat(d, 'mediumTime');
			return formatted;
		}
		else{
			return value;
		}
	}

	const renderEdit = () => {
		if (type === 'bool'){
			// render checkbox
			return (
				<div className='border-2 my-1 border-green-300'>
					<input
						type='checkbox'
						checked={editValue}
						onChange={() => {
							setEditValue(!editValue);
							updateField(fieldId, !editValue);
						}}
						/>
				</div>
			)
		}
		else if (type === 'datetime'){
			// render datetiem selector
			let d = UTCStringToLocalDate(editValue, locationData.time_zone);
			let formatted = dateFormat(d, 'isoDateTime');
			formatted = formatted.substring(0, formatted.length - 5)
			return (
				<input 
					className='border-2 my-1 border-green-300'
					type="datetime-local" 
					// value={'2021-12-28T05:17:10'} 
					value={formatted}
					onChange={(e) => {dateUpdateHelper(e.target.value)}}
					/>
			);
		}
		else if (type === 'int'){
			return (
				<input
					className='border-2 my-1 border-green-300'
					type='number'
					// placeholder={String(editValue)}
					value={editValue}
					onChange={(e) => {
						let val = parseInt(e.target.value);
						setEditValue(val);
						updateField(fieldId, val);
					}} 
					/>
			)
		}
		else{
			// Render regualr input
			return (
				<input
					className='border-2 my-1 border-green-300'
					value={editValue}
					onChange={(e) => {
						let val = e.target.value;
						setEditValue(val);
						updateField(fieldId, val);
					}} 
					/>
			)
		}
	}


	return editMode ? (
		<>
			{renderEdit()}
		</>
	) : (
		<>
			{renderDisplay()}
		</>
	)
}
