/**
	__                     __  _             ____        __       
   / /   ____  _________ _/ /_(_)___  ____  / __ \____ _/ /_____ _
  / /   / __ \/ ___/ __ `/ __/ / __ \/ __ \/ / / / __ `/ __/ __ `/
 / /___/ /_/ / /__/ /_/ / /_/ / /_/ / / / / /_/ / /_/ / /_/ /_/ / 
/_____/\____/\___/\__,_/\__/_/\____/_/ /_/_____/\__,_/\__/\__,_/  
																  
 * 
 * Ken Lipke
 * April 2021
 * 
 * Temporary Holder of location data 
 */

export const  locationIds = [2, 3, 8, 9, 10, 11, 12, 13, 19];

export const locationMap = {
	2 : "#13478 Crenshaw",
	3: "451 Hegenberger Rd.",
	8: "#4302 Magnolia Ave.",
	9: "#66857 1760 W. 6th",
	10: "#14461 Imperial",
	11: "#15121 Slauson",
	12: "#5741 Willow",
	13: "#5464 Carson",
	19: "Half Moon Bay"
}

export const storeNumbers = {
	2: "Subway #13478",
	8: "Subway #4302",
	9: "Subway #66857",
	10: "Subway #14461",
	11: "Subway #15121",
	12: "Subway #5741",
	13: "Subway #5464",
}

// Make the defualt for to include
let _vals = {
		Breakfast: true,
		Lunch: true,
		Afternoon: true,
		Dinner: true,
		Insight: true,
};
Object.values(storeNumbers).forEach(store => {
	['Breakfast', 'Lunch', 'Afternoon', 'Dinner'].forEach(dp => {
		_vals[`${store}, ${dp}`] = true;
	})
})

// Get the defaults based on display names!
let locs = [2, 3, 8, 9, 10, 11, 12, 13, 19, 20, 21, 22, 23, 38, 40, 41];
let _vals2 = {
		Breakfast: true,
		Lunch: true,
		Afternoon: true,
		Dinner: true,
		Evening: true,
		Insight: true,
};
fetch("https://api-iaily4lvlq-uc.a.run.app/location/getLocations", {
	method: "POST",
	headers: {'Content-Type': 'application/json'},
	body: JSON.stringify({ids: locs})
})
.then(r => r.json())
.then(data => {
	let stores = data.map(x => x.display_name);
	stores.forEach(store => {
		["Breakfast", "Lunch", "Afternoon", "Dinner", "Evening"].forEach(dp => {
			_vals2[`${store}, ${dp}`] = true;
			_vals2[`${store}, ${dp}, DT`] = true;
		})
	});
});

export const defaultToInclude = _vals2;

export const comparisons = {
	2: [2, 8, 9, 10, 11, 12, 13],
	8: [2, 8, 9, 10, 11, 12, 13],
	9: [2, 8, 9, 10, 11, 12, 13],
	10: [2, 8, 9, 10, 11, 12, 13], 
	11: [2, 8, 9, 10, 11, 12, 13], 
	12: [2, 8, 9, 10, 11, 12, 13], 
	13: [2, 8, 9, 10, 11, 12, 13],
	3: [3, 19],
	19: [3, 19] 
}

export const defaultAddresses = {
		2:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'eliseo@subsource.com',
		'jonathan.a@subsource.com', 
		'subway13478@gmail.com',
		'Genaro.r@subsource.com'
	],
	8:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'tom.c@subsource.com',
		'nicole.m@subsource.com',
		'4302subway@gmail.com'
	],
	9:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'tom.c@subsource.com',
		'ana.m@subsource.com',
		'subway66857@gmail.com'
	],
	10:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'jonathan.a@subsource.com', 
		'eliseo@subsource.com',
		'Subway14461@gmail.com'
	],
	11:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'jonathan.a@subsource.com', 
		'eliseo@subsource.com',
		'subway15121@gmail.com'
	],
	12:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'eliseo@subsource.com',
		'subway5741@gmail.com'
	],
	13:[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'wesley@subsource.com', 
		'raghu@marwahagroup.com',
		'rajeev@marwahagroup.com',
		'rohit@marwahagroup.com',
		'eliseo@subsource.com',
		'Subway5464@gmail.com'
	],
	"summary": ['Subway14461@gmail.com',
		'rohit@marwahagroup.com',
		'ana.m@subsource.com',
		'tom.c@subsource.com',
		'javi@hellometer.io',
		'jonathan.a@subsource.com',
		'subway15121@gmail.com',
		'4302subway@gmail.com',
		'raghu@marwahagroup.com',
		'eliseo@subsource.com',
		'alexandrea@hellometer.io',
		'subway13478@gmail.com',
		'subway66857@gmail.com',
		'subway5741@gmail.com',
		'Subway5464@gmail.com',
		'rudy@subsource.com',
		'alberto@hellometer.io',
		'rajeev@marwahagroup.com',
		'alex@hellometer.io',
		'wesley@subsource.com',
		'nicole.m@subsource.com',
		'ken@hellometer.io',
		'Genaro.r@subsource.com'
	],
	"roundup": ['Subway14461@gmail.com',
		'rohit@marwahagroup.com',
		'ana.m@subsource.com',
		'tom.c@subsource.com',
		'javi@hellometer.io',
		'jonathan.a@subsource.com',
		'subway15121@gmail.com',
		'4302subway@gmail.com',
		'raghu@marwahagroup.com',
		'eliseo@subsource.com',
		'alexandrea@hellometer.io',
		'subway13478@gmail.com',
		'subway66857@gmail.com',
		'subway5741@gmail.com',
		'Subway5464@gmail.com',
		'rudy@subsource.com',
		'alberto@hellometer.io',
		'rajeev@marwahagroup.com',
		'alex@hellometer.io',
		'wesley@subsource.com',
		'nicole.m@subsource.com',
		'ken@hellometer.io',
		'Genaro.r@subsource.com'
	],
	"old_summary": [
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'javi@hellometer.io',
		'rohit@marwahagroup.com',
		'tom.c@subsource.com',
		'rudy@subsource.com',
		'eliseo@subsource.com',
		'rajeev@marwahagroup.com',
		'raghu@marwahagroup.com',
		'wesley@subsource.com'
	],
	'summary_dq':[
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'javi@hellometer.io',
		'ing.roberto@gmail.com',
		'Armando.Castro@idq.com',
		'Marisol.Segura@idq.com',
		'alilu.med@gmail.com'
	],
	'dq_roundup': [
		'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'javi@hellometer.io',
		'ing.roberto@gmail.com',
		'Armando.Castro@idq.com',
		'Marisol.Segura@idq.com',
		'alilu.med@gmail.com'
	],
    'avs': [
        'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'javi@hellometer.io',
        'ruben.camacho@areas.com',
        'xrios@areas.com',
        'joseantonio.santos@areas.com',
        'raquel.almanza@areas.com',
        'luis.castilla@areas.com',
        'noelia.rastrollo@areas.com',
        'luisdelgadopuig@outlook.es',
        'giglesia@avsservices.es',
        'cfuentes@avsservices.es'
    ],
    'avs_roundup': [
        'ken@hellometer.io', 
        'alicia@hellometer.io',
		'alex@hellometer.io', 
		'alberto@hellometer.io',
		'alexandrea@hellometer.io',
		'javi@hellometer.io',
        'ruben.camacho@areas.com',
        'xrios@areas.com',
        'joseantonio.santos@areas.com',
        'raquel.almanza@areas.com',
        'luis.castilla@areas.com',
        'noelia.rastrollo@areas.com',
        'luisdelgadopuig@outlook.es',
        'giglesia@avsservices.es',
        'cfuentes@avsservices.es'
    ]
}
