import React, { useState, useRef } from "react";
import { useDataContext } from '../DataContext';

export default function CreateUser({ setCreatingAccount, setSelectedCustomer }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [working, setWorking] = useState(false);

	const { routes } = useDataContext();

	const createAccount = () => {
		console.log("Creating Account");
		setWorking(true);

		// Verify email is legit
		if (!validateEmail(email)){
			alert("Please enter a valid email address");
			setWorking(false);
			return;
		}

		// Verify password long enough
		if (password.length < 6){
			alert("Please make a longer password (min 6 characters)");
			setWorking(false);
			return;
		}

		// Appears parameters are sufficient, make call to create account
		// let url = 'http://localhost:5001/customer/createUserFB';
		let url = routes['createUser'];
		fetch(url, {
			method: "POST",
			headers: {"Content-Type": 'application/json'},
			body: JSON.stringify({'email': email, 'password': password})
		})
		.then(res => res.json())
		.then(data => {
			console.log(data.uid);
			setSelectedCustomer(data.uid);
			setCreatingAccount(false);
			setWorking(false);
		})
		.catch((e) => {
			console.warn(e);
			setWorking(false);
			alert("Failed to create account, please try again");
		})

		setWorking(false);
	};

	const validateEmail = (elementValue) => {      
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(elementValue); 
	} 

	/**
	 * Generates a random password and slots it in
	 */
	const generateRandomPassword = () => {
		let r = (Math.random() + 1).toString(36).substring(2);
		setPassword(r);

	}

	return (
		<div className="p-2">
			<div className="text-xl">Create New Account</div>
			<div className="mt-3">
				Email Address
				<br />
				<input
					type="text"
					placeholder="Email Address"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className="border-2 rounded-lg px-2 py-1 border-gray-500"
				/>
			</div>
			<div className="mt-3">
				Password
				<br />
				<input
					type="text"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className="border-2 rounded-lg px-2 py-1 border-gray-500"
				/>
				<button 
					onClick={generateRandomPassword}
					className="btn border-2 rounded-lg px-2 py-1 ml-4 border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white">
					Generate Random Password
				</button>
			</div>
			<div className="flex mt-4">
				<button
					onClick={() => setCreatingAccount(false)}
					className="w-full mx-2 px-2 py-1 rounded-lg border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white">
					Cancel
				</button>
				<button
					disabled={working}
					onClick={createAccount}
					className="w-full mx-2 px-2 py-1 rounded-lg border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white">
					Create
				</button>
			</div>
		</div>
	);
}
