import React, { useState, useEffect } from 'react';
import dateformat from 'dateformat';
import SendModule from '../SendModule';
import { useDataContext } from '../../DataContext';
import CronModule from '../CronModule';

export default function CountEmail(){

	const { routes } = useDataContext();

	const [allLocations, setAllLocations] = useState();
	const [selectedLocation, setSelectedLocation] = useState();

	const [building, setBuilding] = useState(false);
	const [sendParameters, setSendParameters] = useState({});
	const [cronParameters, setCronParameters] = useState({});

	let sd = new Date();
	sd = sd.setDate(sd.getDate() - 2);
	// sd = dateformat(sd, 'yyyy-mm-dd')
	const [startDate, setStartDate] = useState(sd);

	let ed = new Date();
	ed = ed.setDate(ed.getDate() - 1);
	const [endDate, setEndDate] = useState(ed);

	useEffect(() => {
		let url = routes["getAllLocations"];
		fetch(url)
			.then(res => res.json())
			.then(data => {
				setAllLocations(data);
				// setSelectedLocation(data[0]);
				setSelectedLocation(data.filter(x => x.id == 24)[0]);
			})
			.catch(err => console.warn(err));
	}, [])

	/**
	 * Update the parameters required to send the email, and the cron
	 * parameters, for this email type they happen to be the same
	 */
	useEffect(() => {
		let params = {
			start_date: dateformat(startDate, 'yyyy-mm-dd'),
			end_date: dateformat(endDate, 'yyyy-mm-dd'),
			locations: selectedLocation === undefined ? [] : [selectedLocation.id]
		}
		setSendParameters(params);
		setCronParameters(params);
	}, [startDate, endDate, selectedLocation])

	const handleDateChange = (d, which) => {
		let nd = new Date(d);
		let correct = new Date( nd.getTime() - nd.getTimezoneOffset() * -60000 );
		console.log(correct);
		if (which == 'sd'){
			setStartDate(correct);
		}
		else{
			setEndDate(correct);
		}
	}

	/**
	 * Change location state to given id
	 * @param {Int} id location id
	 */
	const handleLocationSelect = (id) => {
		let loc = allLocations.filter(x => x.id == id)[0];
		setSelectedLocation(loc);
	}

	const getFileLink = () => {
		if (selectedLocation === undefined){
			return "#"
		}
		
		// Make logic to get link
		let prefix = 'https://console.cloud.google.com/storage/browser/hellometer_email_files/counts/'
		let datePart = dateformat(startDate, "yyyy/mm/dd");
		let link = prefix + selectedLocation.id + "/" + datePart;
		return link;
	}

	const build = () => {
		setBuilding(true);
		let params = {
			start_date: dateformat(startDate, "yyyy-mm-dd"),
			end_date: dateformat(endDate, 'yyyy-mm-dd'),
			locations: [selectedLocation.id],
		};

		// console.log(params);
		// setBuilding(false);
		// return;

		let url = routes['buildCountEmail'];
		fetch(url, {
			method:'POST',
			mode: 'no-cors',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.text())
		.then(data => {console.log(data); setBuilding(false);})
		.catch(err => {console.warn(err); setBuilding(false);});
	}

	return (
		<>

		<div className='w-full flex justify-center'>
			<div className='grid grid-cols-1 mt-2 border border-gray-300 shadow-lg rounded p-4'>
				<div>
					{
						allLocations != undefined ? (
							<form>
								<label>Select Location</label>
								<select
									className='border border-gray-300 shadow-sm rounded-lg ml-2  px-2 py-1'
									value={selectedLocation === undefined ? null : selectedLocation.id}
									onChange={(e) => handleLocationSelect(e.target.value)}
									>
									{
										allLocations.map(loc => {
											return (
												<option value={loc.id} key={loc.id}>
													{loc.display_name} ({loc.id})
												</option>
											)
										})
									}
								</select>
							</form>
						) : <>Loading locations...</>
					}
				</div>
				<div className='mt-2'>
					<form>
						<label>Select Count Start Date</label>
						<input type='date' id='start'
							value={dateformat(startDate, "yyyy-mm-dd")}
							onChange={(e) => handleDateChange(e.target.value, 'sd')}
							className='border border-gray-300 shadow-sm rounded-lg ml-2 px-2 py-1'/>
					</form>
				</div>
				<div className='mt-2'>
					<form>
						<label>Select Count End Date</label>
						<input type='date' id='start'
							value={dateformat(endDate, "yyyy-mm-dd")}
							onChange={(e) => handleDateChange(e.target.value, 'ed')}
							className='border border-gray-300 shadow-sm rounded-lg ml-2 px-2 py-1'/>
					</form>
				</div>
				<div className='mt-2'>
					<button
						type='button'
						className={`bg-green-500 text-white
							 hover:bg-green-700 py-2 px-4 rounded-lg
							 ${building? 'cursor-wait bg-green-700' : ''}`}
						onClick={build}
						disabled={building}
						>
						Build
					</button>
				</div>
				<div className='mt-2'>
					Once built, can download (then edit and reupload) the count file
					<a
						href={getFileLink()}
						target="_blank"
						className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
						>
						&nbsp;here
					</a>
				</div>
			</div>

		</div>
		
		<div className='mt-8 w-full flex justify-center'>
			<SendModule 
				emailId={selectedLocation != undefined ? selectedLocation.id : null}
				endPoint={routes['sendCountEmail']}
				parameters={sendParameters}/>
		</div>

		<div className='mt-8 w-full flex justify-center'>
			<CronModule 
				emailId={selectedLocation != undefined ? selectedLocation.id : null}
				cronId={selectedLocation != undefined ? 'count__' + selectedLocation.id : null}
				parameters={cronParameters}/>
		</div>

	
		</>
	)
}